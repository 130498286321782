.Wrapper {
  display: flex;
  flex-direction: column;
  gap: 0rem;
}

.mainheader {
  display: flex;
  top: 0;
  position: sticky;
  width: 100%;
  height: 150px;
  overflow: hidden;
  z-index: 20;
  background-color: #ffff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
}
