.home_wrapper {
  width: calc(100% - 128px);
  padding: 1rem 4rem;
  position: relative;
  background-color: #fafafa;
  min-height: calc(100vh - 190px);
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

/* empty */
.empty_products {
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
  align-items: center;
  width: 100%;
  justify-content: center;
}

.empty_image_container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 22rem;
}

.empty_image {
  display: flex;
  align-items: center;
  justify-content: center;
  object-fit: fill;
  width: 100%;
  height: 100%;
}

.empty_portal_1 {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  color: #7a7979;
}

.empty_cell {
  display: grid;
  justify-items: center;
}

.Add_main_container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.Add_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.add_back_btn {
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background-color: transparent;
  border-style: none;
  font-size: 1.5rem;
  border-radius: 5px;
  cursor: pointer;
}

#add_header_h2 {
  flex: 1;
  text-align: center;
  font-size: 1.1rem;
  font-weight: 400;
  margin: 0px;
}

.Notification_container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: 65vh;
  background-color: #ffff;
  border: 1px solid #e6e6e6;
  border-radius: 10px;
  padding: 0.5rem;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
}

.notify_box_container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  height: 75vh;
  overflow: auto;
}

.notifi_box {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid #e6e6e6;
}

.notification {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
  justify-content: center;
}

.user_image_container {
  width: 100%;
  height: 100%;
  align-items: center;
  overflow: hidden;
  border-radius: 50%;
}

.user_initial {
  width: 40px;
  height: 40px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  background-color: transparent;
  color: #fff;
}

.notification_text {
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
}

.n_bold {
  font-size: 1rem;
  color: #2e2d2d;
  margin: 0px;
}

.n_small {
  font-size: 0.9rem;
  color: #7a7979;
  margin: 0px;
}
.time_count {
  display: flex;
  font-size: 0.9rem;
  margin: 0px;
  color: #7a7979;
}

.view_pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: center;
  padding: 0.2rem;
  margin: 0px;
}

.pagination {
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin: 0px;
  padding: 0px;
}

.active-page {
  background-color: #4285f4;
  border: 1px solid #e6e6e6;
  border-radius: 10px;
}

.page-item {
  list-style: none;
  padding: 2px 10px;
  margin: 0 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon_notification {
  font-size: 2rem;
  margin: 0px;
  color: #4285f4;
  align-items: center;
  justify-content: center;
}

.p_payments {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: left;
  justify-content: left;
  padding: 0 1rem;
}
.p_header {
  font-size: 1.2rem;
  font-weight: 600;
  color: #4285f4;
  margin: 0px;
}
.p_payment-header {
  top: 0;
  background-color: #fff;
}

.P_table {
  width: 30%;
  border-collapse: collapse;
  text-align: center;
  overflow-x: scroll;
}

.P_table th {
  padding: 1rem;
  background-color: #4285f4;
  color: #fff;
  /* width: 150px; */
  font-size: 1rem;
  font-weight: 600;
}
.P_table td {
  padding: 0.9rem;
}

.P_table td {
  color: #7a7979;
  font-weight: 500;
  font-size: 0.9rem;
  cursor: pointer;
}

.P_table tr {
  border: 1px solid #ddd;
}

@media screen and (max-width: 900px) {
  .home_wrapper {
    width: calc(100% - 32px);
    position: relative;
  }
}

@media screen and (max-width: 660px) {
  .add_back_btn {
    width: 35px;
    height: 35px;
    font-size: 1.3rem;
  }

  #add_header_h2 {
    font-size: 1.3rem;
  }
  .Notification_container {
    gap: 0.5rem;
    padding: 0.5rem;
  }

  .notifi_box {
    display: flex;
    flex-direction: row;
  }

  .notification {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    align-items: left;
    justify-content: left;
  }

  .notification_text {
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
    text-align: left;
  }

  .n_bold {
    font-size: 0.8rem;
    text-align: left;
  }

  .n_small {
    font-size: 0.6rem;
    text-align: left;
  }
  .time_count {
    font-size: 0.6rem;
  }
}
