/*  about Styles   */
.about_container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  overflow: unset;
}

.about_image {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.about_img {
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  object-fit: fill;
}

.about_content {
  display: flex;
  flex-direction: column;
  text-align: left;
  overflow-wrap: break-word;
  padding: 2rem;
  gap: 1rem;
}

.about_text {
  font-size: 1rem;
  font-weight: 500;
  font-family: "Muli";
  margin: 0px;
  padding: 1rem;
}

.about_contact {
  font-size: 0.9rem;
  font-family: "Muli";
  margin: 0px;
  padding: 1rem;
}
