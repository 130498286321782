body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: #f6f4fc; */
}

::-webkit-scrollbar {
  display: none;
}

*{
  font-family: "Muli";
}
