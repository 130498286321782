
.reg_container {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.side_image {
  position: relative;
  width: 100%;
  max-width: 800px;
  height: 100vh;
}

.side_image .Tanyya_image {
  width: 100%;
  height: 100vh;
  display: block;
  z-index: 1;
}

.zero_mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: #4285f4b3;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  z-index: 2;
}

.model_text {
  padding: 0 20px;
}

.register_div {
  display: flex;
  width: 100%;
  max-width: 800px;
  height: 90vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1.5rem 0;
}

.logoheader {
  width: 100%;
  max-width: 300px;
  height: auto;
  /* padding: 20px 10px; */
  /* margin-bottom: 20px; */
}

.register_types {
  display: grid;
  grid-template-columns: 1fr;
  padding: 0.5rem 0;
}

.register_type {
  display: flex;
  justify-content: space-between;
  border: 1px solid #474646;
  background-color: #ffffff;
  align-items: center;
  padding: 5px;
  width: 300px;
  margin: 10px 0;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.register_type:hover {
  background-color: #f5f5f5;
}

.reg_img {
  width: 50px;
  height: auto;
}

.reg_text {
  color: #474646;
  font-size: 1rem;
  padding: 10px;
}

/* form----Register styles---- */

.register_form {
  height: 100vh;
  overflow-x: hidden;
  overflow-y: scroll;
  padding: 3rem 0;
}
.register_form label {
  display: block;
  font-weight: bold;
  color: #333;
  margin-bottom: 5px;
}

.register_form input {
  width: 450px;
  /* max-width: 500px; */
  border-radius: 4px;
  text-indent: 10px;
  border: 1px solid #ccc;
  font-size: 1.1rem;
  margin-bottom: 10px;
  padding: 10px;
}

.select-wrapper {
  position: relative;
  margin-bottom: 10px;
}

select {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
  box-sizing: border-box;
}

.error-message {
  color: red;
  margin-top: 5px;
}

.register_form .reg_bt,
.reg_bt2 {
  text-align: center;
  margin-top: 10px;
  font-size: 1rem;
  background-color: #20a0ff;
  color: #fff;
  border: none;
  font-size: 1.5rem;
  border-radius: 4px;
  cursor: pointer;
  padding: 8px;
}

.register_form p {
  position: relative;
  margin: 0;
  color: red;
  font-size: 14px;
  right: 0px;
  align-self: flex-end;
  margin-top: -10px;
}

.password-input {
  position: relative;
}

.password-input svg {
  position: absolute;
  top: 55%;
  transform: translateY(-50%);
  right: 10px;
  cursor: pointer;
  color: #4285f4b3;
}
@media screen and (max-width: 920px) {
  .reg_container {
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
  }

  .side_image {
    display: block;
  }

  .register_div {
    max-width: 400px;
    justify-content: center;
  }

  .logoheader {
    /* width: 90%; */
    height: auto;
    /* margin: 20px 0; */
  }

  .register_type {
    width: 100%;
    max-width: 300px;
  }
  .register_form {
    height: 80vh;
    overflow-y: unset;
    padding: 2rem 0;
  }
  .register_form input {
    /* width: 100%;  */
    font-size: 15px;
    max-width: 350px;
  }

  .reg_img {
    width: 50px;
    height: auto 50px;
  }

  .reg_text {
    color: #474646;
    font-size: 0.8rem;
  }
}
@media screen and (max-width: 780px) {
  .reg_container {
    grid-template-columns: 1fr;
    justify-items: center;
  }
  .side_image {
    display: none;
  }
  .register_div {
    max-width: 500px;
    height: auto;
    justify-content: center;
  }
  .logoheader {
    /* width: 100%; */
    height: auto;
    margin: 20px 0;
  }
  .register_form {
    height: 100vh;
    overflow-y: unset;
    padding: 1rem 0;
  }
  .register_type {
    width: 500px;
  }
  .register_form input {
    /* width: 100%;  */
    max-width: 400px;
  }

  .reg_img {
    width: 80px;
    height: auto;
  }

  .reg_text {
    color: #474646;
    font-size: 0.8rem;
  }
}

@media screen and (max-width: 600px) {
  .logoheader {
    /* width: 100%; */
    height: auto;
  }
  .reg_container {
    grid-template-columns: 1fr;
  }
  .register_div {
    width: 100%;
    height: auto;
  }
  .register_form {
    overflow-x: hidden;
    overflow-y: unset;
    padding: 2rem;
  }
  .register_type {
    width: 100%;
    height: auto;
  }
  .register_form input {
    width: 350px;
    max-width: none;
  }

  .reg_img {
    width: 80px;
    height: auto;
  }

  .reg_text {
    color: #474646;
    font-size: 0.8rem;
  }
}
@media screen and (max-width: 480px) {
  .register_div {
    width: 100%;
    height: auto;
  }
  .register_form {
    overflow-y: unset;
    padding: 1rem;
  }
  .register_type {
    width: 100%;
    height: auto;
    padding: 0 10px;
  }
  .register_form input {
    width: 280px;
    max-width: none;
  }

  .reg_img {
    width: 80px;
    height: auto;
  }

  .reg_text {
    color: #474646;
    font-size: 0.9rem;
  }
}
