.User_wrapper {
  width: calc(100% - 128px);
  padding: 1rem 4rem;
  position: relative;
  background-color: #fafafa;
  height: calc((100vh - 186px));
  overflow: auto;
}
.subscription_container {
  display: flex;
  align-items: center;
  justify-content: space-around;
  border: 1px solid #e6e6e6;
  background-color: #d9e3eb;
  border-radius: 10px;
  padding: 1rem;
}
.denied {
  font-size: 1.3rem;
  font-weight: 500;
  margin: 0px;
  color: #ea4335;
  padding: 0px;
}

.Buyer_wrapper {
  padding: 1rem;
  position: relative;
  left: 0px;
  height: 95vh;
  width: 100%;
}

.Buyer_wrapper-1 {
  justify-self: center;
  width: 100%;
  max-width: 600px;
  margin-right: 170px;
}

.Buyer_wrappe-container {
  display: grid;
  width: 100%;
}

.group_container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1.5rem;
  position: relative;
}

.group_containe {
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
  position: relative;
}

.group_box {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background: #ffff;
  position: relative;
  padding: 0.5rem;
  height: 70vh;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}

#user_groups {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  margin: 0px;
}

.UGname {
  text-align: center;
  margin: 0px;
  font-size: 1.1rem;
  color: #7a7979;
}
.UGbtn {
  margin: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.user_group_container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  height: 70vh;
  overflow-y: auto;
}

.groups_container {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  position: relative;
}

.group_box_c {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
}

.active_group {
  color: #4285f4;
}

.user_icon_container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px;
  padding: 5px;
}

.users_icon {
  margin-right: 10px;
  font-size: 1.2rem;
  /* background-color: #ea4335; */
  color: #2e2d2d;
  padding: 10px;
  border-radius: 25px;
  margin: 0px;
}

.user_group {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0px;
}

#group_h6 {
  font-size: 1rem;
  color: #474646;
  width: 150px;
  overflow-wrap: break-word;
  padding: 0px;
  margin: 0px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

#activeText {
  margin: 0px;
  font-size: 1rem;
  padding: 0px;
}

#activeText2 {
  margin: 0px;
  font-size: 0.8rem;
  padding: 0px;
}

#group_li {
  font-size: 0.8rem;
  color: #7a7979;
  list-style: none;
  margin: 0px;
  text-align: left;
}

.group_icon_container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.group_btn_dlt {
  border: none;
  background-color: transparent;
  color: #ea4335;
  font-size: 1rem;
}

.add_group_btn {
  position: absolute;
  /* top: 92%; */
  right: 10px;
  bottom: 10px;
}

.add_groups {
  border: 1px solid #4285f4;
  padding: 0.4rem 0.5rem;
  font-size: 1.3rem;
  align-self: center;
  justify-content: center;
  background-color: #4285f4;
  color: #fff;
  border-radius: 50%;
  cursor: pointer;
}

.group_users_products {
  display: flex;
  flex-direction: column;
  align-content: baseline;
  gap: 1rem;
  background-color: #ffff;
  padding: 0.5rem;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  max-height: 70vh;
}

.group_users_product_c {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-content: baseline;
  gap: 1rem;
  background-color: transparent;
  min-height: 70vh;
}

.group_btns_apis {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}
.group_users_1,
.group_products_2 {
  border: 1px solid #f1f6fb;
  padding: 10px;
  color: #4285f4;
  background-color: #f1f6fb;
  font-size: 1rem;
  border-radius: 5px;
  cursor: pointer;
}

.active_group_btn {
  background-color: #4285f4;
  border: 1px solid #4285f4;
  color: #ffff;
  font-size: 1rem;
}

.group_products_container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  overflow: scroll;
}

.group_users_boxes {
  display: flex;
  flex-direction: column;
  height: 70vh;
  gap: 1rem;
  overflow: auto;
}

.group_user_details_btn {
  border-style: none;
  background-color: transparent;
  cursor: pointer;
  margin: 0px;
}

.group_user_container {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  cursor: pointer;
}

.user_image_container {
  width: 50px;
  height: 50px;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border-radius: 50%;
  padding: 0.5rem;
}
.group_user_image {
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  object-fit: cover;
  border-radius: 50%;
}

.group_user_initial {
  width: 100%;
  height: 100%;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
  color: #000000;
  border-radius: 50%;
}

.group_user_info {
  display: flex;
  flex-direction: column;
  padding: 0px;
  justify-content: center;
}

.group_user_name {
  text-align: left;
  justify-content: center;
  padding: 0px;
  margin: 0px;
  font-size: 1rem;
  color: #2e2d2d;
}

#group_user_number {
  text-align: left;
  justify-content: center;
  padding: 0px;
  margin: 0px;
  color: #474646;
  font-size: 0.8rem;
}

#group_user_type {
  text-align: left;
  justify-content: center;
  padding: 0px;
  margin: 0px;
  color: #474646;
  font-size: 0.8rem;
}

.group_products_boxes {
  display: flex;
  flex-direction: column;
  height: 62vh;
  gap: 1rem;
  overflow: auto;
}

.product_box {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: left;
  cursor: pointer;
  padding: 0px 0.5rem;
}

.group_images_container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 80px;
}

.group_product_img {
  width: 100%;
  height: 100%;
  object-fit: fill;
  /* overflow: hidden; */
}

.product_info_container {
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
  text-align: left;
  padding: 0px;
}
#g_p_name {
  font-size: 1rem;
  color: #2e2d2d;
  width: 200px;
  overflow-wrap: break-word;
  padding: 0px;
  margin: 0px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
#g_p_Q {
  font-size: 0.8rem;
  margin: 0px;
  padding: 0px;
  color: #7a7979;
}

#group_product_price {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  margin: 0px;
}

#group_p_price {
  display: flex;
  font-size: 0.8rem;
  align-items: center;
  color: #fbbc05;
}

#group_product_edit {
  border-style: none;
  font-size: 1rem;
  color: #2e2d2d;
  background-color: transparent;
  cursor: pointer;
}

.groups_add_btns {
  position: absolute;
  right: 10px;
  bottom: 10px;
}

.add_user {
  border: 1px solid #4285f4;
  padding: 0.4rem 0.5rem;
  font-size: 1.3rem;
  display: flex;
  align-self: center;
  justify-content: center;
  background-color: #4285f4;
  color: #fff;
  border-radius: 50%;
  cursor: pointer;
}

.add_product {
  border: 1px solid #34a853;
  font-size: 1rem;
  background-color: #34a853;
  color: #fff;
  border-radius: 50%;
  cursor: pointer;
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
/* Loader  styles */
.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.loader2 {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20vh;
}

/* for  empty */
.empty_products {
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
  align-items: center;
  width: 100%;
  justify-content: center;
}

.empty_image_container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20rem;
}

.e_image_container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15px;
  height: 15px;
}

.empty_image {
  display: flex;
  align-items: center;
  justify-content: center;
  object-fit: fill;
  width: 100%;
  height: 100%;
}

.empty_portal_1 {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  margin: 0px;
  color: #7a7979;
  padding: 0.2rem;
}
/*  */
/* ------users styles------ */

.Users_container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: 100%;
  background-color: transparent;
}
#users_text {
  font-size: 0.8rem;
  padding: 0 10px;
  margin: 0px;
}
#users_h2text {
  font-size: 1rem;
  font-weight: 400;
  color: #4285f4;
  padding: 0 5px;
  margin: 0px;
}
.users_userdata_container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: 58vh;
  overflow: auto;
  position: relative;
}
.add_ownusers {
  position: absolute;
  right: 10px;
  bottom: 10px;
}

.add_ownuser {
  border: 1px solid #4285f4;
  padding: 0.3rem 0.5rem;
  display: flex;
  font-size: 1.3rem;
  align-self: center;
  justify-content: center;
  background-color: #4285f4;
  color: #fff;
  border-radius: 50%;
  cursor: pointer;
}
.add_ownuser:disabled {
  background-color: lightgray !important;
  border: none;
}
/* Add users */
.Add_user_container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.Add__user_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Add_back_btn {
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background-color: #ffff;
  border-style: none;
  font-size: 2rem;
  border-radius: 5px;
  cursor: pointer;
}

#Add_user_header_h1 {
  flex: 1;
  text-align: center;
  font-size: 1.1rem;
  font-weight: 400;
  margin: 0px;
}

.Add_users_form_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.Add_users_form {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 500px;
  align-items: left;
  justify-content: left;
  padding: 1rem;
  gap: 1rem;
  border: 1px solid #e6e6e6;
  background-color: #ffff;
  border-radius: 10px;
  height: 64vh;
}

.Add-serch-usear {
  background-color: white !important;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  border: 1px solid #d5d6d7;
  border-radius: 36px;
  padding: 0.2rem 0.4rem;
  box-shadow: 0px 4px 60px rgba(0, 0, 0, 0.08);
}

.view_search {
  width: 100px;
  height: 25px;
  font-size: 0.9rem;
  border: none;
  padding: 0px;
}

.view_search_img {
  width: 15px;
  height: 15px;
  margin: 0px;
}
.users_select_c {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  overflow: scroll;
  height: 70vh;
}

.Add-serch-usear input {
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  font-size: 1rem;
  width: 100%;
  margin: 0px;
}

.user_checkbox_container {
  display: flex;
  flex-direction: row;
  gap: 0.6rem;
  align-items: center;
  /* justify-content: center; */
}
.user_checkbox_container input {
  width: 1rem;
  height: 1rem;
  border: 1px solid #e6e6e6;
  cursor: pointer;
  margin: 0px;
}

.Add_users_info_box {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.6rem;
}

.Add_image_container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  padding: 5px;
}

.Add_user_image {
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  object-fit: fill;
  border-radius: 50%;
}

.Add_user_initial {
  width: 100%;
  height: 100%;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
  background-color: #ccc;
  color: #fff;
  border-radius: 50%;
}

.Add_users_info_text {
  display: flex;
  flex-direction: column;
  padding: 0px;
  justify-content: center;
}

#Add_companytype {
  font-size: 1rem;
  text-align: left;
  justify-content: center;
  padding: 0px;
  margin: 0px;
  color: #4285f4;
}

#Add_number {
  text-align: left;
  justify-content: center;
  padding: 0px;
  margin: 0px;
  color: #474646;
  font-size: 0.9rem;
}

#Add_a_type {
  text-align: left;
  justify-content: center;
  padding: 0px;
  margin: 0px;
  color: #474646;
  font-size: 0.9rem;
}
#add_address {
  text-align: left;
  justify-content: center;
  padding: 0px;
  margin: 0px;
  color: #474646;
  font-size: 0.9rem;
}

#user_UID {
  text-align: left;
  justify-content: center;
  padding: 0px;
  margin: 0px;
  color: #7a7979;
  font-size: 0.8rem;
}

.user_address {
  font-size: 0.9rem;
  padding: 0px;
  margin: 0px;
  color: #7a7979;
  word-wrap: none;
  overflow: hidden;
  width: 390px;
  text-overflow: ellipsis;
}
#users_loading {
  color: #ea4335;
  font-size: 1rem;
}

.Add_users_btn {
  border: 1px solid #4285f4;
  color: #ffff;
  border-radius: 35px;
  background-color: #4285f4;
  width: 100%;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  align-items: center;
  justify-content: center;
}
/* ------Create group styles----- */
.group_create {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.create_group_submit {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  position: relative;
  height: 55vh;
}

.create_group_submit label {
  font-size: 1rem;
  padding: 0px;
  margin: 0px;
}

.create_group_submit input {
  width: 98%;
  height: 30px;
  border: 1px solid #e6e6e6;
  text-indent: 10px;
  font-size: 1.3rem;
  border-radius: 5px;
}

#s_count {
  color: #4285f4;
  font-size: 1.3rem;
  margin: 0px;
}
.create_s_users_list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  overflow: scroll;
  height: 100vh;
}

.create_user_info {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e6e6e6;
  padding: 0.5rem 0;
}

#create_c_name {
  color: #2a2d2d;
  font-size: 1.1rem;
  padding: 0px;
  margin: 0px;
}

#create_a_type {
  color: #7a7979;
  font-size: 1rem;
  padding: 0px;
  margin: 0px;
}
.create_remove_btn {
  align-items: center;
  justify-content: center;
}
#create_remove_user {
  border-style: none;
  font-size: 1.2rem;
  background-color: #ffff;
}

/*----- AddGroup products Styles----- */

.groupproduct_container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.groupproduct_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.groupproduct_back_btn {
  align-items: center;
  justify-content: center;
  /* width: 50px;
  height: 50px; */
  background-color: transparent;
  border-style: none;
  font-size: 1.5rem;
  border-radius: 5px;
  cursor: pointer;
}

#groupproduct_header_h1 {
  flex: 1;
  text-align: center;
  font-size: 1.1rem;
  font-weight: 400;
  margin: 0px;
}

.groupproduct_form_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.groupproduct_form {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 500px;
  align-items: left;
  justify-content: left;
  padding: 1rem;
  gap: 1rem;
  border: 1px solid #e6e6e6;
  background-color: #ffff;
  border-radius: 10px;
  height: 60vh;
  overflow: auto;
}
.groupheader_select_container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.groupproduct-serch-usear {
  background-color: white !important;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  border: 1px solid #d5d6d7;
  border-radius: 36px;
  padding: 0.5rem 0.5rem;
  box-shadow: 0px 4px 60px rgba(0, 0, 0, 0.08);
}

.g_product_search {
  width: 20px;
  height: 20px;
  font-size: 1rem;
}

.groupproduct-serch-usear input {
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  width: 100%;
  margin: 0px;
}
.selectallbtn {
  display: flex;
  flex-direction: row;
  gap: 0.4rem;
  font-size: 1rem;
  font-weight: 500;
}
.selectallbtn input {
  width: 1rem;
  height: 1rem;
}

.group_Product_container {
  display: flex;
  flex-direction: column;
  gap: 0.6rem;
  min-height: 40vh;
  overflow: auto;
}

.groupproduct_checkbox_container {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
  /* justify-content: center; */
}
.groupproduct_checkbox_container input {
  width: 15px;
  height: 15px;
  border: 1px solid #e6e6e6;
}

.groupproduct_info_box {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

.groupproduct_image_container {
  width: 70px;
  height: 70px;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  /* padding: 1rem; */
}

.groupproduct_image {
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  object-fit: fill;
  border-radius: 10px;
}

.groupproduct_add_initial {
  width: 100%;
  height: 100%;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2;
  background-color: #ccc;
  color: #fff;
}

.groupproduct_info_text {
  display: flex;
  flex-direction: column;
  padding: 0px;
  justify-content: center;
}

#groupproduct_prod_name {
  font-size: 1.2rem;
  text-align: left;
  justify-content: center;
  padding: 0px;
  margin: 0px;
  color: #2e2d2d;
  overflow-wrap: break-word;
  width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

#groupproduct_invoice {
  text-align: left;
  justify-content: center;
  padding: 0px;
  margin: 0px;
  color: #474646;
  font-size: 0.9rem;
}

#groupproduct_stock_q {
  text-align: left;
  justify-content: center;
  padding: 0px;
  margin: 0px;
  color: #474646;
  font-size: 0.9rem;
}

#groupproduct_loading {
  color: red;
  font-size: 1rem;
}

.groupproduct_add_btn {
  border: 1px solid #34a853;
  color: #ffff;
  border-radius: 35px;
  background-color: #34a853;
  width: 100%;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

/*---- group User Details  styles ----- */

.user_d_container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.user_d_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.user_d_back_btn {
  display: flex;
  align-items: center;
  justify-content: left;
  width: 40px;
  height: 40px;
  background-color: transparent;
  border-style: none;
  font-size: 1.5rem;
  border-radius: 5px;
  cursor: pointer;
}

#user_d_header_h1 {
  flex: 1;
  text-align: center;
  font-size: 1.1rem;
  font-weight: 400;
  margin: 0px;
}
.user_d_main_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.user_d_sub_container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  background-color: #ffff;
  width: 100%;
  max-width: 600px;
  border-radius: 5px;
}
.user_d_header_container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  pad: 1rem;
  align-items: center;
  justify-content: center;
  background-color: #d3ead0;
  border-top: 5px;
  position: relative;
}

.user_d_image {
  margin-top: 3rem;
  width: 70px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border-radius: 50%;
  /* padding: 1rem; */
}

.user_d_img {
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  object-fit: cover;
  border-radius: 50%;
}

.user_d_imd {
  width: 100%;
}

.user_d_initial {
  width: 100%;
  height: 100%;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  background-color: #ccc;
  color: #fff;
  border-radius: 50%;
}
.user_d_header_text {
  display: flex;
  flex-direction: column;
}

#user_d_username {
  font-size: 1.1rem;
  font-weight: 600;
  color: #191919;
  padding: 5px 0px;
  margin: 0px;
  text-align: center;
}

#user_d_uid {
  font-size: 0.9rem;
  font-weight: 600;
  color: #7a7979;
  padding: 5px 0px;
  margin: 0px;
}
.user_d_access_btn {
  position: absolute;
  right: 10px;
  top: 10px;
}

.user_d_settings_btn {
  background-color: #d3ead0;
  border-style: none;
  color: #fbbc05;
  font-size: 1.3rem;
  cursor: pointer;
}
.user_d_i_container {
  display: flex;
  flex-direction: column;
  border: 1px solid #e6e6e6;
}
.user_d_info_container {
  padding: 0 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  border-radius: 5px;
}

.user_d_text1,
.user_d_text2,
.user_d_text3 {
  display: flex;
  font-family: "Muli";
  flex-direction: column;
  gap: 0.3rem;
  font-size: 1rem;
  padding-bottom: 0.3rem;
  color: #191919;
  margin: 0px;
}

#user_d_n {
  font-size: 1rem;
  font-weight: 600;
  font-family: "Muli";
  color: #7a7979;
  margin: 0px;
}

.user_d_delete_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.4rem;
  margin: 0px;
}
.user_d_d_btn {
  background-color: #ffff;
  border: 1px solid #fc0d0e;
  color: #fc0d0e;
  font-size: 1.4rem;
  padding: 0.5rem 3rem;
  border-radius: 32px;
  cursor: pointer;
}
.user_d_text1 {
  position: relative;
}

.user_d_call {
  position: absolute;
  right: 10px;
  top: 10px;
  font-size: 1.5rem;
  color: #fbbc05;
  cursor: pointer;
}

/*------ Permission styles  --- */
.staff_permission_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.permission_co {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 600px;
  gap: 0.5rem;
  height: 66vh;
  overflow: auto;
}

.permission_container {
  display: flex;
  flex-direction: column;
  background-color: transparent;
}

.permission_types {
  display: flex;
  flex-direction: column;
  background-color: transparent;
}

#permisssion_type {
  font-size: 1.2rem;
  font-weight: 600;
  color: #2e2d2d;
  text-transform: capitalize;
}

.permission_type_box {
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
  margin: 0px;
}

.permission_cont {
  display: flex;
  justify-content: space-between;
  align-items: center;
  justify-items: center;
}

.permission_t {
  align-items: center;
  justify-content: center;
}
#permissionName {
  font-size: 1.1rem;
  color: #191919;
  margin: 0px;
  text-transform: capitalize;
}
.permission_btn {
  align-items: center;
  justify-content: center;
}
.switch_btn {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 22px;
}

.switch_btn input {
  opacity: 0;
  width: 0;
  height: 0;
  cursor: pointer;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 20px;
  left: 2px;
  bottom: 2px;
  background-color: #ffff;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #fbbc05;
}

input:focus + .slider {
  box-shadow: 0 0 1px #fbbc05;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

/*---- Product styles--- */

.product_main_container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.product_main_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.prod_back_btn {
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background-color: #ffff;
  border-style: none;
  font-size: 1.5rem;
  border-radius: 5px;
  cursor: pointer;
}

#product_header_h2 {
  flex: 1;
  text-align: center;
  font-size: 1.5rem;
  margin: 0px;
}

.product_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
}

.product_container_2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 600px;
  gap: 0.3rem;
  background-color: #ffff;
  border: 1px solid #fafafa;
  box-shadow: 0px 3.48px 3.48px 0px rgba(0, 0, 0, 0.25);
}
.product_container_header {
  display: flex;
  /* align-items: center; */
  justify-content: center;
  width: 100%;
  height: 30vh;
}

.prod_image_container {
  margin: 0 auto;
  width: 100%;
  min-height: 100px;
}

.prod_image {
  display: flex;
  position: relative;
  top: 0%;
  margin: 0px;
  width: 100%;
  height: 20vh;
  background-color: #def5ff;
  width: 200px;
  min-height: 200px;
  overflow: hidden;
}

.prod_img {
  position: absolute;
  left: 50%;
  top: 100px;
  transform: translate(-50%, -50%);
  width: 150px;
  height: 150px;
  object-fit: fill;
  z-index: 1;
}
.product_info {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;
}

#product_text_1 {
  font-size: 1.1rem;
  text-align: center;
  width: 250px;
  overflow-wrap: break-word;
  padding: 0px;
  margin: 0px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.product_qty {
  font-size: 1rem;
  margin: 0px;
  color: #7a7979;
}

#product_text_2 {
  font-size: 0.9rem;
  margin: 0px;
}

#product_text_3 {
  font-size: 1rem;
  background-color: #34a853;
  color: #ffff;
  border: 1.2px solid #34a853;
  padding: 0.3rem 2rem;
  border-radius: 32px;
  margin: 0px;
}

#product_text_4 {
  display: flex;
  flex-direction: row;
  align-items: left;
  justify-content: left;
  gap: 10px;
  margin: 0px;
}

.prod_details_logo {
  width: 50px;
  height: 50px;
  flex: 1;
  align-content: left;
  justify-content: center;
  margin: 0;
}

#prod_details_text {
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
  /* text-align: left; */
  align-items: left;
  justify-content: center;
}
#product_price_box {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  color: #fbbc05;
  margin: 0px;
}

#product_gst {
  font-size: 1rem;
  margin: 0px;
  color: #34a853;
}

#prod_mesure_p {
  font-size: 0.8rem;
  padding: 0;
  margin: 0;
  align-items: left;
  justify-content: center;
}

#prod_units {
  font-size: 1rem;
  padding: 0;
  margin: 0;
  align-items: left;
  justify-content: center;
}

.prod_btn_conatiner {
  display: flex;
  justify-content: center;
  gap: 2rem;
  padding: 1rem;
}

.prod_action_delete {
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  padding: 0.5rem 4rem;
  color: #fc0d0e;
  background-color: #ffff;
  border: 1.2px solid #fc0d0e;
  border-radius: 32px;
  cursor: pointer;
}

.prod_action_edit {
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  padding: 0.5rem 4rem;
  background-color: #34a853;
  border: 1.2px solid #34a853;
  color: #ffff;
  border-radius: 32px;
  cursor: pointer;
}

/* ----delete Model styles ----*/

.delete-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.delete-modal-content {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  max-width: 350px;
  height: 350px;
  background: #ffff;
  padding: 1rem;
  border-radius: 10px;
  align-items: center;
}

.user-modal-content {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  max-width: 300px;
  height: 250px;
  background: #ffff;
  padding: 1rem;
  border-radius: 10px;
  align-items: center;
}

#delete_header {
  color: #7a7979;
  text-align: start;
  padding: 5px;
  margin: 0px;
}

.delete-modal-img {
  width: 100px;
  height: 100px;
  object-fit: fill;
  align-items: center;
  justify-content: center;
}

#delete_text1 {
  font-family: "Kumbh Sans-Medium", Helvetica;
  font-size: 1rem;
  margin: 0px;
  padding: 5px;
  color: #2e2d2d;
  text-align: center;
}

#delete_conform_text {
  font-size: 1rem;
  color: #797979;
  padding: 10px 0;
  margin: 0px;
  text-align: center;
}
.delete_btns {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin: 0px;
}

.delete_cbtn,
.delete_dbtn {
  padding: 0.8rem 2.5rem;
  font-size: 1rem;
  cursor: pointer;
  border: none;
  border-radius: 4px;
  outline: none;
  border-radius: 32px;
}

.delete_cbtn {
  background-color: #ffff;
  border: 1px solid #34a853;
  color: #34a853;
}

.delete_dbtn {
  background-color: #fc0d0e;
  color: #fff;
  border: 1px solid #fc0d0e;
}

.home_wrapper.blur {
  filter: blur(5px);
}

/* ---Edit model  ---- */

.edit-modal-content {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
  max-width: 350px;
  height: 350px;
  background: #ffff;
  padding: 1rem;
  border-radius: 10px;
  align-items: center;
  position: relative;
}

#edit_header {
  color: #7a7979;
  text-align: start;
  padding: 5px;
  margin: 0px;
}
.price_form {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;
}

.edit-modal-img {
  width: 100px;
  height: 100px;
  object-fit: fill;
  align-items: center;
  justify-content: center;
}

#edit_text1 {
  font-size: 1rem;
  text-align: center;
  width: 200px;
  overflow-wrap: break-word;
  padding: 0px;
  margin: 0px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

#edit_text2 {
  font-size: 1.1rem;
  color: #797979;
  padding: 5px 0;
  margin: 0px;
  text-align: center;
}
.edit_btns {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin: 0px;
}

.edit_adbtn {
  width: 100%;
  background-color: #34a853;
  border: 1px solid #34a853;
  color: #ffff;
  padding: 0.8rem 3rem;
  font-size: 1rem;
  cursor: pointer;
  border: none;
  border-radius: 4px;
  outline: none;
  border-radius: 32px;
  margin: 0px;
}
.delete_acbtn {
  width: 100%;
  background-color: #fc0d0e;
  border: 1px solid #fc0d0e;
  color: #ffff;
  padding: 0.8rem 3rem;
  font-size: 1rem;
  cursor: pointer;
  border: none;
  border-radius: 4px;
  outline: none;
  border-radius: 32px;
  margin: 0px;
}
.edit_input_conatiner {
  display: flex;
  flex-direction: row;
  border: 2px solid #e6e6e6;
  border-radius: 5px;
  gap: 1rem;
  position: relative;
  align-items: center;
  width: 300px;
  height: 40px;
  margin: 0px;
}

.edit_input_box {
  position: relative;
  border-style: none;
  outline: none;
  width: 60%;
  height: 30px;
  font-size: 1.1rem;
  text-indent: 1.8rem;
}

.price_text {
  font-size: 1rem;
  color: #fbbc05;
  margin: 0px;
  align-items: center;
  justify-content: center;
}

.rupee_icon {
  position: absolute;
  left: 5px;
  top: 48%;
  transform: translateY(-50%);
  color: #34a853;
  font-size: 1.6rem;
}

/* edit Modal */
.reject_modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.overlaye {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}
.update_modal_content {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 100%;
  max-width: 280px;
  height: 300px;
  background: #ffff;
  padding: 1rem;
  border-radius: 10px;
  position: relative;
  /* align-items: center; */
  justify-content: center;
  text-align: left;
}

#resct_header {
  font-size: 1.1rem;
  color: #7a7979;
  margin: 0px;
  text-align: center;
}

.update_input_model_container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  position: relative;
}

.banner_input input {
  width: 100%;
  height: 30px;
  text-indent: 0.5rem;
  border: 1px solid #e6e6e6;
  border-radius: 5px;
}

.banner_form_btns {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
  justify-content: right;
}

.group_e_btn {
  border: 1px solid #4285f4;
  background-color: #4285f4;
  color: #ffff;
  padding: 0.5rem 2rem;
  font-size: 1rem;
  font-weight: 600;
  border-radius: 32px;
  cursor: pointer;
}
.group_dlt_btn {
  border: 1px solid #fc0d0e;
  background-color: #fc0d0e;
  color: #ffff;
  padding: 0.5rem 2rem;
  font-size: 1rem;
  border-radius: 32px;
  cursor: pointer;
}

.group_btn_edit {
  border: none;
  color: #4285f4;
  font-size: 1rem;
  margin: 0px;
  cursor: pointer;
  background-color: transparent;
}

@media screen and (max-width: 900px) {
  .User_wrapper {
    width: calc(100% - 102px);
    padding: 1rem;
    position: relative;
    left: 70px;
    background-color: #f6f4fc;
    overflow: hidden;
  }

  .group_container {
    display: grid;
    grid-template-columns: 40% 58%;
    gap: 1rem;
  }

  .user_icon_container {
    padding: 5px;
  }

  .users_icon {
    font-size: 1rem;
    padding: 5px;
  }

  .user_group #group_h6 {
    font-size: 0.7em;
    text-align: center;
    padding: 0 5px;
  }

  #group_li {
    font-size: 0.6rem;
    text-align: center;
  }

  .add_group_btn {
    right: 5px;
    bottom: 5px;
  }

  .add_groups {
    padding: 0.2rem 0.5rem;
    font-size: 0.9rem;
  }

  .group_users_products {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  .group_btns_apis {
    gap: 0.5rem;
  }

  .group_users_1,
  .group_products_2 {
    padding: 4px;
    font-size: 0.7rem;
  }

  .active_group_btn {
    font-size: 0.7rem;
  }

  .group_products_container {
    gap: 0.5rem;
  }
  .group_users_boxes {
    gap: 0.5rem;
  }

  .group_user_container {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  .user_image_container {
    width: 40px;
    height: 40px;
    padding: 0px;
  }

  .group_user_initial {
    font-size: 1.1rem;
  }

  .group_user_name {
    font-size: 0.8rem;
  }

  #group_user_number {
    font-size: 0.7rem;
  }

  #group_user_type {
    font-size: 0.7rem;
  }

  .group_products_boxes {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  .product_box {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding: 0px 0.4rem;
  }

  .group_images_container {
    width: 60px;
    height: 60px;
  }

  #g_p_name {
    font-size: 0.8rem;
  }

  #g_p_invoice {
    font-size: 0.7rem;
  }
  #g_p_Q {
    font-size: 0.8rem;
  }

  #group_p_price {
    font-size: 0.9rem;
  }

  .groups_add_btns {
    right: 5px;
    bottom: 5px;
  }

  .add_user {
    padding: 0.3rem 0.5rem;
    font-size: 1rem;
  }

  .add_product {
    padding: 0.3rem 0.5rem;
    font-size: 1rem;
  }

  .Users_container {
    padding: 0.8rem;
  }

  #users_text {
    font-size: 0.7rem;
  }

  #users_h2text {
    font-size: 1.1rem;
  }

  /* Add users */
  .Add_user_container {
    gap: 1.5rem;
  }

  .Add_users_form {
    max-width: 90%;
    gap: 0.6rem;
  }

  .Add-serch-usear {
    gap: 0.6rem;
  }

  .view_search {
    width: 40px;
    height: 20px;
    font-size: 0.8rem;
  }

  .user_checkbox_container {
    gap: 0.6rem;
  }
  .user_checkbox_container input {
    width: 15px;
    height: 15px;
  }

  .Add_users_info_box {
    gap: 0.6rem;
  }

  .Add_image_container {
    width: 50px;
    height: 50px;
  }

  .Add_user_initial {
    font-size: 1.2;
  }

  #Add_companytype {
    font-size: 0.9rem;
  }

  #Add_number {
    font-size: 0.8rem;
  }

  #Add_a_type {
    font-size: 0.8rem;
  }

  .Add_users_btn {
    width: 100%;
    padding: 0.4rem 0.8rem;
    font-size: 1.1rem;
  }
  /* ------Create group styles----- */

  .group_create {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .create_s_users_list {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    overflow: scroll;
    height: 70vh;
  }
  .create_group_submit {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    position: relative;
    height: 50vh;
  }

  .create_group_submit label {
    font-size: 0.8rem;
    padding: 5px;
  }

  .create_group_submit input {
    height: 20px;
    font-size: 1rem;
    margin: 0px;
    align-items: center;
    justify-content: center;
  }

  #s_count {
    font-size: 1.1rem;
  }

  .create_user_info {
    padding: 0.4rem 0;
  }

  #create_c_name {
    font-size: 0.9rem;
  }

  #create_a_type {
    font-size: 0.8rem;
  }

  #create_remove_user {
    font-size: 1rem;
  }

  /*----- AddGroup products Styles----- */

  .groupproduct_container {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }

  .groupproduct_back_btn {
    width: 40px;
    height: 40px;
    font-size: 1.2rem;
  }

  #groupproduct_header_h1 {
    font-size: 1.2rem;
  }

  .groupproduct_form {
    max-width: 90%;
    padding: 0.5rem;
    gap: 1rem;
  }

  .groupproduct-serch-usear {
    gap: 0.6rem;
    padding: 0.3rem 0.4rem;
  }

  .g_product_search {
    width: 15px;
    height: 15px;
    font-size: 0.9rem;
  }

  .groupproduct_checkbox_container {
    gap: 0.6rem;
  }
  .groupproduct_checkbox_container input {
    width: 15px;
    height: 15px;
  }

  .groupproduct_info_box {
    display: flex;
    flex-direction: row;
    gap: 0.6rem;
  }

  .groupproduct_image_container {
    width: 50px;
    height: 50px;
    padding: 0.5rem;
  }

  #groupproduct_prod_name {
    font-size: 0.9rem;
  }

  #groupproduct_invoice {
    font-size: 0.8rem;
  }

  #groupproduct_stock_q {
    font-size: 0.9rem;
  }

  #groupproduct_loading {
    color: red;
    font-size: 1rem;
  }

  .groupproduct_add_btn {
    padding: 0.5rem 1rem;
    font-size: 1.1rem;
  }

  .user_d_container {
    gap: 1.5rem;
  }

  .user_d_back_btn {
    width: 40px;
    height: 40px;
    font-size: 1.4rem;
  }

  #user_d_header_h1 {
    flex: 1;
    text-align: center;
    font-size: 1.4rem;
    margin: 0px;
  }

  .user_d_sub_container {
    max-width: 100%;
    gap: 1rem;
  }

  .user_d_header_container {
    pad: 0.5rem;
  }

  .user_d_image {
    margin-top: 2rem;
    width: 100px;
    height: 100px;
    padding: 0.5rem;
  }

  .user_d_initial {
    font-size: 1.4rem;
  }

  #user_d_username {
    font-size: 1.1rem;
  }

  #user_d_uid {
    font-size: 0.9rem;
  }

  .user_d_settings_btn {
    font-size: 1.1rem;
  }

  .user_d_info_container {
    padding: 0.5rem;
    gap: 0.6rem;
  }

  .user_d_text1,
  .user_d_text2,
  .user_d_text3 {
    font-size: 0.9rem;
  }
  #user_d_n {
    font-size: 1.1rem;
  }

  .user_d_delete_btn {
    padding: 0.5rem;
  }

  .user_d_d_btn {
    font-size: 1.1rem;
    padding: 0.4rem 2rem;
  }
  .user_d_text1 {
    position: relative;
  }

  .user_d_call {
    font-size: 1.2rem;
  }

  /*------ Permission styles  --- */
  .permission_container {
    max-width: 100%;
    padding: 0.5rem;
  }

  #permisssion_type {
    font-size: 0.9rem;
  }

  #permissionName {
    font-size: 0.9rem;
  }

  .switch_btn {
    width: 48px;
    height: 26px;
  }

  .slider:before {
    height: 20px;
    width: 20px;
    left: 2px;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 25px;
  }

  /*---- Product styles--- */

  .product_main_container {
    gap: 1.5rem;
  }

  .prod_back_btn {
    font-size: 1.4rem;
  }

  #product_header_h2 {
    font-size: 1.4rem;
  }

  .product_container {
    gap: 1rem;
  }

  .product_container_2 {
    max-width: 100%;
    gap: 1rem;
  }

  .prod_img {
    width: 120px;
    height: 120px;
  }
  .product_info {
    display: flex;
    flex-direction: column;
    padding: 5px 0;
    align-items: center;
    justify-content: center;
  }

  #product_text_1 {
    font-size: 0.9rem;
  }

  #product_text_2 {
    font-size: 0.8rem;
    margin: 0px;
  }

  #product_text_3 {
    font-size: 0.9rem;
    padding: 0.3rem 1rem;
  }

  .prod_details_logo {
    width: 40px;
    height: 40px;
  }

  #product_price_box {
    font-size: 1.5rem;
    font-weight: 700;
  }

  #prod_mesure_p {
    font-size: 0.8rem;
  }

  #prod_units {
    font-size: 0.9rem;
  }

  .prod_btn_conatiner {
    display: flex;
    justify-content: center;
    gap: 1.5rem;
    padding: 0.5rem;
  }

  .prod_action_delete {
    font-size: 1rem;
    padding: 0.4rem 3rem;
  }

  .prod_action_edit {
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    padding: 0.4rem 3rem;
  }
}
@media screen and (max-width: 520px) {
  .group_container {
    display: grid;
    grid-template-columns: 33% 65%;
    gap: 0.5rem;
    position: relative;
  }

  .groups_container {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
  }

  .group_box_c {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding: 0px;
  }
  .group_icon_container {
    position: absolute;
    right: 0%;
    top: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px;
    /* overflow: unset; */
  }

  .group_btn_dlt {
    border: none;
    background-color: transparent;
    color: #fc0d0e;
    font-size: 0.8rem;
  }
}

@media screen and (max-width: 450px) {
  .prod_btn_conatiner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1rem;
    padding: 0.5rem;
  }
  .group_users_1,
  .group_products_2 {
    font-size: 0.6rem;
    padding: 3px;
  }
}
