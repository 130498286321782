.home_wrapper {
  position: relative;
  background-color: #fafafa;
  min-height: calc(100vh - 182px);
  padding: 1rem 4rem;
}
/*  permission */

.permission_container {
  width: 100%;
  height: 90vh;
  background-color: #ffff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 2rem;
}
.permission_container h2 {
  font-size: 1.1rem;
  font-family: "Muli";
  margin-left: 0.5rem;
  width: 100%;
  max-width: 300px;
}

.permission_container img {
  width: 100%;
  max-width: 500px;
}

/* empty handle */

.empty_products {
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
  align-items: center;
  width: 100%;
  justify-content: center;
}

.empty_image_container {
  display: flex;
  align-items: center;
  justify-content: center;
  /* width: 300px; */
  height: 380px;
}

.empty_image {
  display: flex;
  align-items: center;
  justify-content: center;
  object-fit: fill;
  width: 100%;
  height: 100%;
}

.empty_portal_1 {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.1rem;
  font-weight: 600;
  color: #7a7979;
}

.empty_cell {
  display: grid;
  justify-items: center;
}
/*  */

.sticky-header {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: #fff;
}

.Order_container {
  display: flex;
  position: relative;
  flex-direction: column;
  gap: 1rem;
  border: 1px solid #fafafa;
  background-color: #ffff;
  box-shadow: 0px 3.48px 3.48px 0px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  padding: 20px;
}

.order_status_select {
  display: none;
}

.Order_statusbtn {
  display: flex;
  border-style: none;
  flex-direction: row;
  justify-content: space-between;
  gap: 0.5rem;
}

.order_statusbuttons {
  color: #2e2d2d;
  border-style: none;
  background-color: #ffff;
  cursor: pointer;
  font-size: 1rem;
  min-height: 30px;
}

.order_statusbuttons:hover {
  border-color: 3px solid #4285f4;
}

.order_statusbuttons.active {
  color: #4285f4;
  border-bottom: 3px solid #4285f4;
}

.Order_table_container {
  width: 100%;
  align-items: center;
  justify-content: center;
  height: 55vh;
  overflow: auto;
}

.Order_table {
  width: 100%;
  border-collapse: collapse;
  text-align: center;
  overflow-x: scroll;
}

.Order_table th {
  padding: 0.6rem;
  background-color: #4285f4;
  color: #fff;
  width: 150px;
  font-size: 1rem;
  font-weight: 400;
}

.Order_table td {
  padding: 0.9rem;
}

.Order_table td {
  color: #212121;
  font-size: 0.9rem;
  cursor: pointer;
}

.Order_table tr {
  border: 1px solid #ddd;
}

.orderbyId_btn {
  border: none;
  background-color: transparent;
  color: #4285f4;
  font-size: 0.9rem;
}

.send_remainder {
  border: none;
  background-color: transparent;
  color: #4285f4;
  font-size: 1rem;
  margin: 0px;
}

.view_pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* padding: 2rem 0; */
}

.pagination {
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin: 0px;
  padding: 0px;
}

.active-page {
  background-color: #4285f4;
  border-radius: 10px;
}

.page-item {
  list-style: none;
  padding: 2px 8px;
  margin: 0 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

#view_page_count {
  font-size: 0.7rem;
}

#view_page_count .number {
  font-weight: 600;
  font-size: 0.7rem;
  color: #4285f4;
}

.order_pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  /* padding: 1rem 0; */
  margin: 0px;
}

.reorder_button {
  border: 1px solid #34a853;
  color: #ffff;
  background-color: #34a853;
  font-size: 0.9rem;
  font-weight: 600;
  border-radius: 5px;
  padding: 0.5rem;
  cursor: pointer;
}

/* Loader */
.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

/* Model   styles */
.reject_modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.overlaye {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.update_modal_content {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 100%;
  max-width: 280px;
  height: 300px;
  background: #ffff;
  padding: 1rem;
  border-radius: 10px;
  position: relative;
  /* align-items: center; */
  justify-content: center;
  text-align: left;
}
#resct_header {
  font-size: 1.1rem;
  color: #7a7979;
  margin: 0px;
  text-align: center;
}
.update_input_model_container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  position: relative;
}

.banner_input {
  display: flex;
  align-items: center;
}

.reject_input_modal {
  width: 100%;
  height: 100px;
  align-items: center;
  text-indent: 1rem;
  font-size: 1rem;
  border: 1px solid #e6e6e6;
  border-radius: 5px;
}

@media screen and(min-width: 650px) {
  .home_wrapper {
    width: calc(100% - 32px);
  }
  .order_status_select {
    display: flex;
    float: right;
    align-content: end;
    justify-self: end;
  }
}
