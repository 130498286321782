.mysubscription_container {
  display: flex;
  flex-direction: column;
  height: 70vh;
  overflow: auto;
  position: relative;
}

.mysubscription {
  display: flex;
  flex-direction: column;
  text-align: left;
  gap: 2rem;
}

.subscription_plan {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 0.5rem;
  border-radius: 10px;
  background-color: #f2f2f2;
  cursor: pointer;
}
.Inactive_text {
  font-size: 1rem;
  margin: 0px;
  color: #4285f4;
}

.plan_header {
  font-size: 1rem;
  color: #2a2d2d;
  margin: 0px;
  color: #4285f4;
}
.subsstylecontainer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
}
.stylecontainer {
  background-image: url("../../../assets/freetrail.png");
  background-repeat: no-repeat;
  background-size: cover;
  /* max-height: 150px; */
  display: flex;
  flex-direction: column;
  gap: 0.6rem;
  color: #ffff;
  padding: 1rem;
  border-radius: 10px;
}
.header {
  font-size: 1.2rem;
  font-family: "Muli";
  font-weight: 600;
  margin: 0px;
  color: #ffff;
}
.sdate,
.edate {
  font-size: 1rem;
  font-weight: 500;
  margin: 0px;
}
.sbtext {
  font-size: 0.8rem;
  margin: 0px;
}

.stylecontainer2 {
  background-image: url("../../../assets/Billcycle.png");
  background-repeat: no-repeat;
  background-size: cover;
  /* max-height: 150px; */
  display: flex;
  flex-direction: column;
  gap: 0.6rem;
  color: #ffff;
  padding: 1rem;
  border-radius: 10px;
}

.Payment_details {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.deactive_btn {
  background-color: #4285f4;
  color: #ffff;
  margin: 0px;
  border-radius: 30px;
  font-size: 0.9rem;
  font-weight: 600;
  border: 1px solid #4285f4;
  padding: 0.5rem;
}

.plan_users {
  font-size: 1rem;
  color: #7a7979;
  margin: 0px;
}

.total_users {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  /* border-bottom: 2px solid #e6e6e6; */
}
.Subs_container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.ss_container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.Ousers,
.Musers,
.tusers {
  font-size: 1rem;
  color: #7a7979;
  margin: 0px;
  padding: 0px;
}

.payment_header {
  font-size: 1.1rem;
  color: #7a7979;
  margin: 0px;
}
.from_date {
  font-size: 1rem;
  color: #7a7979;
  margin: 0px;
}
.to_date {
  font-size: 1rem;
  color: #7a7979;
  margin: 0px;
}

/* table styles */
.P_table {
  width: 100%;
  border-collapse: collapse;
  text-align: center;
  overflow-x: scroll;
}

.P_table th {
  padding: 0.6rem;
  font-weight: 400;
  background-color: #4285f4;
  color: #ffff;
  font-size: 1rem;
}
.P_table td {
  padding: 0.9rem;
}

.P_table td {
  color: #212121;
  font-size: 0.9rem;
  cursor: pointer;
}

.P_table tr {
  border: 1px solid #ddd;
}

/*Payment styles  */

.payment_details {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.product_main_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.prod_back_btn {
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background-color: #ffff;
  border-style: none;
  font-size: 1.5rem;
  border-radius: 5px;
  cursor: pointer;
}

#product_header_h2 {
  flex: 1;
  text-align: center;
  font-size: 1rem;
  margin: 0px;
}

.payment_container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background-color: #ffff;
  padding: 1rem;
  align-items: center;
}

.address_container_2 {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 500px;
  height: 83vh;
  gap: 1rem;
  border: 1px solid #e6e6e6;
  background-color: #ffff;
  border-radius: 5px;
  position: relative;
}

.login_text_container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;
}

.conform_ammount {
  font-size: 1rem;
  margin: 0px;
}

.confirm_todate {
  font-size: 1rem;
  margin: 0px;
}

.bill_container {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  padding: 0 0.5rem;
  max-width: 100%;
  border-bottom: 1px solid #e6e6e6;
}

.bill_cycle {
  display: flex;
  justify-content: space-between;
  margin: 0px;
  font-size: 0.9rem;
}

.bill_cycle_header {
  align-items: center;
  justify-content: center;
  margin: 0px;
  font-size: 0.9rem;
}

.bill_cycle_text {
  align-items: center;
  justify-content: center;
  margin: 0px;
  font-size: 0.9rem;
}

.total_bill {
  display: flex;
  justify-content: space-between;
  margin: 0px;
  padding: 0 1rem;
}

.total_bill_header {
  font-size: 1rem;
  margin: 0px;
}

.total_bill_text {
  font-size: 1rem;
  margin: 0px;
}

.confirm_button {
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  left: 50%;
  transform: translate(-50%, -50%);
  gap: 1rem;
  bottom: 5px;
}

.payment_s_text {
  display: flex;
  text-align: center;
  border-bottom: 1px solid #4285f4;
  font-size: 1.1rem;
  font-weight: 500;
  margin: 0px;
  color: #4285f4;
}

.cub_btn {
  border: 1px solid #e6e6e6;
  color: #4285f4;
  font-size: 0.9rem;
  font-weight: 600;
  margin: 0px;
  padding: 0.4rem 2rem;
  background-color: transparent;
  border-radius: 32px;
}

.sub_btn {
  border: 1px solid #4285f4;
  background-color: #4285f4;
  color: #ffff;
  font-size: 0.9rem;
  font-weight: 600;
  margin: 0px;
  padding: 0.4rem 2rem;
  border-radius: 32px;
}

/* model  styles */

.delete-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.delete-modal-content {
  display: flex;
  flex-direction: column;
  position: relative;
  gap: 5rem;
  width: 100%;
  max-width: 330px;
  height: 400px;
  background: #ffff;
  padding: 1rem;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
}

/* .user-modal-content {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  max-width: 350px;
  height: auto;
  background: #ffff;
  padding: 1rem;
  border-radius: 10px;
  align-items: center;
} */

#delete_header {
  color: #7a7979;
  font-weight: 500;
  text-align: center;
  align-items: center;
  justify-content: center;
  padding: 5px;
  margin: 0px;
}

#delete_text1 {
  font-family: "Kumbh Sans-Medium", Helvetica;
  font-size: 1rem;
  margin: 0px;
  padding: 5px;
  color: #2e2d2d;
  text-align: center;
  align-items: center;
  justify-content: center;
}

#delete_conform_text {
  font-size: 1.1rem;
  color: #797979;
  padding: 10px 0;
  margin: 0px;
  text-align: center;
}
.delete_btns {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin: 0px;
}

.deactivate_model_btn {
  border: none;
  color: #2a2d2d;
  background-color: #ffff;
  font-size: 0.9;
  padding: 0.5rem 2rem;
  border-radius: 32px;
}

.deactivate_btn {
  border: 1px solid #4285f4;
  color: #ffff;
  background-color: #4285f4;
  font-size: 0.9;
  padding: 0.5rem 2rem;
  border-radius: 32px;
}

.close_modal {
  position: absolute;
  background-color: #ea4335;
  color: #aaa;
  top: 0px;
  right: 0%;
  padding: 2px 4px;
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
}

.calcel_back_btn {
  border: none;
  color: #2a2d2d;
  background-color: #ffff;
  font-size: 0.9;
  padding: 0.5rem 2rem;
  border-radius: 32px;
}

.home_wrapper.blur {
  filter: blur(5px);
}

.Bill_container_box {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
}

.Bill_container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: 0px;
  width: 100%;
  border-bottom: 2px solid #e6e6e6;
}
.Bill_cycle {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  justify-content: space-between;
}

.Bill_cycle_header {
  align-items: center;
  justify-content: center;
  font-size: 0.8rem;
  font-weight: 500;
  margin: 0px;
  color: #2a2d2d;
}
.Bill_cycle_text {
  font-size: 0.8rem;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  margin: 0px;
  color: #2a2d2d;
}

.Total_bill {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.Total_bill_header {
  font-size: 1rem;
  font-weight: 600;
  margin: 0px;
  color: #2a2d2d;
}
.details_hedaer {
  color: #7a7979;
  font-size: 1.1rem;
  font-weight: 400;
  margin: 0px;
  padding: 0.5rem 0;
}
.Total_bill_text {
  font-size: 1rem;
  margin: 0px;
  color: #2a2d2d;
}
.container_payment {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.date_container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  /* align-items: center; */
}
.from_date {
  font-size: 0.9rem;
  margin: 0px;
  color: #797979;
}

.from_date {
  font-size: 0.9rem;
  margin: 0px;
  color: #797979;
}

.to_date {
  font-size: 0.9rem;
  margin: 0px;
  color: #797979;
}

.valid_plan {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  /* align-items: center; */
}

.payment_sts {
  font-size: 0.9rem;
  margin: 0px;
  font-weight: 600;
  color: #2a2d2d;
  text-align: start;
}
.sub_sts {
  font-size: 0.9rem;
  margin: 0px;
  font-weight: 600;
  color: #2a2d2d;
  text-align: start;
}
/* cancel model */
.reject_modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.overlaye {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}
.update_modal_content {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 100%;
  max-width: 280px;
  height: 300px;
  background: #ffff;
  padding: 1rem;
  border-radius: 10px;
  position: relative;
  /* align-items: center; */
  justify-content: center;
  text-align: left;
}

#resct_header {
  font-size: 1.1rem;
  color: #7a7979;
  margin: 0px;
  text-align: center;
}

.update_input_model_container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  position: relative;
}

.cancel_text {
  font-size: 1rem;
  text-align: center;
  font-weight: 600;
  margin: 0px;
  color: #2e2d2d;
}

.banner_form_btns {
  display: flex;
  align-items: center;
  gap: 1rem;
  justify-content: center;
}
.banner_btn {
  border-style: none;
  color: #2a2d2d;
  padding: 0.5rem 2rem;
  font-size: 1rem;
  font-weight: 600;
  border-radius: 32px;
  cursor: pointer;
}
.banner_e_btn {
  border: 1px solid #ea4335;
  background-color: #ea4335;
  color: #ffff;
  padding: 0.5rem 2rem;
  font-size: 1rem;
  font-weight: 600;
  border-radius: 32px;
  cursor: pointer;
}

@media screen and (max-width: 720px) {
}
