.privacy_container {
  /* padding-left: 80px;
  padding-right: 80px;
  padding-top: 40px;
  padding-bottom: 40px; */
}
.privacy_header {
  font-size: 1.8rem;
  font-weight: 900;
  font-family: sans-serif;
  text-align: center;
  padding: 10px;
}
.privacy_content {
  text-align: justify;
}
.privacy_content_li {
  font-size: 20px;
  color: #000;
  font-family: muli;
  font-weight: 600;
}
.privacy_content_p {
  font-size: 18px;
  padding: 10px;
  font-family: muli;
}
.privacy_content_contact_details {
  padding-left: 10px;
  font-size: 18px;
  padding: 5px;
}

@media (max-width: 768px) {
  .privacy_container {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .privacy_header {
    font-size: 24px;
  }

  .privacy_content_li {
    font-size: 20px;
  }

  .privacy_content_p {
    font-size: 16px;
  }

  .privacy_content_contact_details {
    font-size: 16px;
  }
}
