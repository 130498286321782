.homenav_wrapper {
  display: flex;
  flex-direction: column;
  width: 230px;
  border: 2px solid #e6e6e6;
  height: 100vh;
  /* height: 100%; */
  top: 0;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
  overflow: scroll;
  left: 220px;
  z-index: 90;
  background-color: #ffff;
  position: fixed;
}
.homenav_wrapper::-webkit-scrollbar {
  display: none;
}

.buyers_container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 0.5rem;
  height: 100vh;
  position: relative;
}
.buyers_container_2 {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 0.5rem;
  height: 90vh;
}

.buyers_header {
  font-size: 1rem;
  font-weight: 600;
  margin: 0px;
  color: #4285f4;
}

.buyers_boxces {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.buyer_box {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
  border: none;
  background-color: transparent;
}

.buyer_imgcontainer {
  width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border-radius: 50%;
  padding: 0px;
  /* margin-right: 10px; */
}

.buyer_image {
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  object-fit: cover;
  border-radius: 50%;
}

.buyer_initial {
  width: 100%;
  height: 100%;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
  background-color: #ccc;
  color: #fff;
  border-radius: 50%;
}

.buyer_details {
  display: flex;
  flex-direction: column;
  text-align: left;
}
.group_user_name {
  color: #423f3a;
  font-size: 0.9rem;
  font-weight: 600;
  margin: 0px;
}

#group_user_number {
  color: #423f3a;
  margin: 0px;
  font-size: 0.9rem;
}

#group_user_type {
  color: #423f3a;
  margin: 0px;
  font-size: 0.8rem;
}
.add_buyers {
  position: absolute;
  bottom: 5px;
  right: 5px;
  align-items: center;
  justify-content: center;
}

.add_ownbuyer {
  border: 1px solid #4285f4;
  padding: 0.3rem 0.8rem;
  font-size: 1.3rem;
  align-self: center;
  justify-content: center;
  background-color: #4285f4;
  color: #fff;
  border-radius: 50%;
  cursor: pointer;
}

/*  sections styles   */
.sections_container {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
  margin: 0;
  padding: 0.5rem;
  border-bottom: 1px solid #e6e6e6;
}
#quickbuttons {
  text-align: left;
  padding: 0 10px;
  margin: 0;
  font-size: 0.6rem;
}
.user_btn_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  /* padding: 10px; */
  border-radius: 10px;
  gap: 2rem;
  cursor: pointer;
}

.active_button {
  color: #4285f4 !important;
  background-color: #f1f6fb !important;
  cursor: pointer;
}

.Users_icon {
  font-size: 1.5rem;
  background-color: #f2f2f2;
  border-radius: 10px;
  padding: 10px;
  color: #adabab;
}

.home_manage,
.home_sales {
  display: flex;
  justify-content: flex-start;
  padding: 5px;
  align-items: center;
  margin: 0px;
  text-align: left;
  cursor: pointer;
  border-style: none;
  background-color: #ffff;
  text-decoration: none;
}
.home_manage:hover,
.home_sales:hover {
  background-color: #f1f6fb;
  color: #4285f4;
  border-radius: 10px;
}

#home_nav_h6 {
  font-size: 0.8rem;
  font-weight: 600;
  padding: 0 1rem;
  align-items: center;
  justify-content: center;
  margin: 10px 0;
}

/* Users_Groups .....styles....... */

.home_users_conatiner {
  display: grid;
  grid-template-columns: 1fr;
  padding: 1rem;
  gap: 10px;
}

#user_groups {
  text-align: left;
  padding: 0 10px;
  margin: 0;
  font-size: 0.8rem;
}
.user_btn_container {
  border-style: none;
  background-color: transparent;
}

.groups_container {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}

.users_icon {
  margin-right: 10px;
  font-size: 1.5rem;
  background-color: #dddd;
  color: #ffff;
  padding: 10px;
  border-radius: 25px;
}

.user_group {
  display: flex;
  flex-direction: column;
  padding: 10px;
  margin: 0;
}

.user_group #group_h6 {
  font-size: 20px;
  font-weight: 600;
  padding: 0;
  margin: 0;
  color: #474646;
}

#group_li {
  font-size: 0.9rem;
  color: #423f3a;
  list-style: none;
}

/*   Responsive Designs    */

@media screen and (max-width: 900px) {
  .homenav_wrapper {
    width: 70px;
    overflow: scroll;
    left: 70px;
  }

  .user_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0px;
  }
  .user_image_container {
    width: 40px;
    height: 40px;
    align-items: center;
    padding: 5px;
  }
  .user_info {
    display: none;
  }

  .sections_container {
    padding: 0px;
  }

  #quickbuttons {
    padding: 0 5px;
    font-size: 0.6rem;
  }
  .user_btn_container {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  .Users_icon {
    font-size: 1.1rem;
    padding: 0.3rem;
  }

  #home_nav_h6 {
    font-size: 0.8rem;
    font-weight: 600;
    padding: 0px;
    margin: 0px;
  }
}
