/* --------loader--------- */
.circle-loader {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  animation: linear normal forwards;
  animation-name: run;
  animation-duration: 100ms;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  transition: 100ms;
}
.loader {
  /* margin-left: 15rem; */
  display: grid;
  align-items: center;
  justify-items: center;
}

/* ---------------error---------------------- */
.modal {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);

  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10000;
}
.modal-content {
  display: grid;
  /* justify-items: center; */
  gap: 2rem;
  opacity: 1;
  max-width: 500px;
  width: 100%;
  /* padding: 0.5rem 0; */
  background: #ffffff;
  border-radius: 5px;
  position: relative;
}
.header {
  padding: 0.5rem 1rem 0.5rem 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #cecaca;
  background-color: #ff0000;
}
.warning .header {
  background-color: #ff6700;
}
.header > div {
  display: flex;
  gap: 0.3rem;
  align-items: center;
}
.header p {
  margin: 0;
  padding: 0;
  font-size: 1.2rem;
  color: white;
  font-family: "Source Sans Pro", sans-serif;
}
.header button {
  border: none;
  background-color: inherit;
}
.spinner {
  display: grid;
  /* justify-items: center; */
  gap: 2rem;
  opacity: 1;
  /* max-width: 600px; */
  justify-items: center;
  width: 100%;
  padding: 2rem 0;
  /* background: #ffffff; */
  border-radius: 14px;
  position: relative;
}
.modal-content form {
  display: grid;
  gap: 0.5rem;
  align-items: center;
}
.modal-content > p {
  color: #716d6d;
  font-family: sans-serif;
  font-weight: 500;
  margin-left: 2rem !important;
}
.control-btns {
  padding: 0.8rem 2rem 0.8rem 0;
  display: grid;
  gap: 1rem;
  justify-items: right;
  background-color: #cecaca;
}
.control-btns button {
  cursor: pointer;
  font-size: 1rem;
  padding: 0.3rem 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.submit-btn {
  background-color: blue;
  color: white;
  border: 1px solid blue;
}

.delete-btn {
  border: 1px solid #7a7979;
  background-color: #ffff;
  border-radius: 10px;
}
.warning .control-btns button {
  width: 5rem;
  height: 2.5rem;
  padding: 0;
}
.warning .control-btns {
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
  /* grid-template-columns: 1fr 1fr; */
}
.warning .proceed {
  background-color: white;
  border: 2px solid #7a7979;
  border-radius: 10px;
}
.close-icon {
  cursor: pointer;
}
/* Empty  404 */
.main_content {
  display: flex;
  align-items: center;
  justify-content: center;
}
.img_container img {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
}

.warntext {
  font-size: 1.1rem;
  color: #000001;
  margin: 0px;
  font-weight: 600;
}

.content_btns {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  margin-bottom: 0.5rem;
}

.google-play-button {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
  border: 2px solid #000001;
  background-color: #000001;
  border-radius: 32px;
  padding: 0.5rem 1rem;
}

.image_container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
}
.googlePlay {
  width: 100%;
}

.image_container svg {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
}

.text_container {
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
}
.google-play-icon2 {
  color: #ffff;
}

.text1,
.text2 {
  font-size: 1rem;
  font-weight: 500;
  margin: 0px;
  color: #ffff;
}

@media screen and (max-width: 800px) {
  .modal-content {
    gap: 1rem;
    margin: 1rem;
    height: fit-content;
    padding: 0.1rem;
  }

  .text1,
  .text2 {
    font-size: 0.9rem;
    font-weight: 500;
    margin: 0px;
    color: #ffff;
  }
  .googlePlay,
  .google-play-icon2 {
    size: 30px;
  }
}
@media screen and (max-width: 400px) {
  .modal-content {
    max-width: 100%;
  }

  .googlePlay,
  .google-play-icon2 {
    font-size: 20px;
  }
  .text1,
  .text2 {
    font-size: 0.8rem;
    font-weight: 500;
    margin: 0px;
    color: #ffff;
  }
}
