.pricing {
  background-color: #ebeef1;
}

.pricing-container {
  display: flex;
  flex-direction: row;
  font-size: 30px;
  text-align: center;
  gap: 2rem;
  margin: 40px;
  font-family: Muli;
  background-color: #ebeef1;
}

.pricing-item-one {
  background-color: #ffffff;
  padding: 10px;
  margin: 10px;
  flex: 1;
  /* border: 1px solid #e6e6e6; */
  border: 3px solid #ccc;
  border-radius: 10px;
  box-shadow: 0 8px 17px rgb(0 0 0 / 5%);
  transition: all 0.2s;
}

.pricing-item-one:hover {
  box-shadow: 2px 12px 15px #999;
}
.pricing-item-one.with-transform:hover {
  transform: translateY(-10px);
}

.pricing-item-two {
  background-color: #ffffff;
  padding: 10px;
  margin: 10px;
  flex: 1;
  border: 3px solid #ccc;
  border-radius: 10px;
  box-shadow: 0 8px 17px rgb(0 0 0 / 5%);
  transition: all 0.2s;
}

.pricing-item-two:hover {
  box-shadow: 2px 12px 15px #999;
}
.pricing-item-two.with-transform:hover {
  transform: translateY(-10px);
}

.pricing-item-three {
  background-color: #ffffff;
  padding: 10px;
  margin: 10px;
  flex: 1;
  border: 3px solid #ccc;
  border-radius: 10px;
  box-shadow: 0 8px 17px rgb(0 0 0 / 5%);
}

.pricing-item-three:hover {
  box-shadow: 2px 12px 15px #999;
}
.pricing-item-three.with-transform:hover {
  transform: translateY(-10px);
}

.Pricing_header {
  text-align: center;
  padding-top: 40px;
  text-shadow: 0 0 3px #2a2d2d;
}

.pricing_Manu_content {
  list-style-type: none;
  text-align: left;
}

.pricing-cost-number {
  padding-bottom: 20px;
}
.pricing-item-header {
  text-align: center;
  padding-left: 10px;
  color: #20a0ff;
  margin: 10px;
}
.pricing-item-header_free {
  text-align: center;
  padding-left: 10px;
  color: #fc0d0e;
  margin: 10px;
}
.pricing-item-header_Dis {
  color: #7cdc01;
  text-align: center;
  margin: 10px;
}
.pricing_button {
  width: 60%;
  padding: 10px;
  margin: 10px;
  background-color: #20a0ff;
  color: #fff;
  border: none;
  font-size: 20px;
  border-radius: 5px;
}
.pricing_button_Dis_two {
  width: 60%;
  padding: 10px;
  background-color: #20a0ff;
  color: #fff;
  border: none;
  font-size: 20px;
  border-radius: 5px;
}

.pricing_button_Dis_one {
  width: 60%;
  padding: 10px;
  background-color: #fc0d0e;
  color: #fff;
  border: none;
  font-size: 20px;
  border-radius: 5px;
  margin-top: 3.8rem;
}

.pricing_button_Dis_three {
  width: 60%;
  padding: 10px;
  background-color: #7cdc01;
  color: #fff;
  border: none;
  font-size: 20px;
  border-radius: 5px;
}
.pricing-list-items {
  font-size: 14px;
  text-align: center;
}

.pricing-list-limit {
  font-size: 16px;
  text-align: center;
  padding: 20px;
  font-weight: 600;
  list-style-type: none;
}

.pricing-checks {
  font-size: 1rem;
  word-spacing: 2px;
  list-style-type: none;
  padding-left: 20px;
  text-align: left;
}
.pricing-item-cost {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
}

.pricing-item-cost > div {
  padding-bottom: 14px;
  /* padding-top: 10px; */
}
.pricing-checks {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.pricing_free_header {
  font-size: 1.4rem;
  font-weight: bold;
  text-align: center;
  margin: 0px;
}

.pricing_free_list {
  padding: 19px;
}

.pricing_check_icon {
  color: gray;
  font-family: Muli;
  padding: 0px 4px 0px 4px;
  line-height: 4vh;
  font-size: 2vh;
  font-weight: 600;
}
.pricing_icon {
  padding-top: 10px;
}

/* Media query for mobile devices */
@media only screen and (max-width: 990px) {
  .pricing_Manu_content {
    list-style-type: none;
    text-align: center;
    padding: 0px;
  }
  .pricing-container {
    flex-direction: column;
    margin: auto;
    width: 80%;
  }
}
@media only screen and (max-width: 320px) {
  .pricing_Manu_content {
    list-style-type: none;
    text-align: center;
    padding: 0px;
  }
  .pricing-container {
    flex-direction: column;
    margin: auto;
    width: 100%;
  }
}
@media only screen and (max-width: 600px) {
  .pricing-container {
    flex-direction: column;
    margin: auto;
  }

  .pricing-item-left,
  .pricing-item-right {
    margin: 10px;
  }

  .pricing-button {
    width: 80%;
  }

  .pricing_icon {
    width: 20px;
    height: 20px;
    margin-right: 5px;
  }
}
