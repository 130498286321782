.faqs_container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.faq_box {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.accordion {
  background-color: #ffff;
  color: #333;
  cursor: pointer;
  padding: 0;
  width: 100%;
  border-style: none;
  text-align: left;
  border-bottom: 1px solid #ddd;
  outline: none;
  transition: background-color 0.3s;
  display: flex;
  gap: 0.5rem;
  justify-content: space-between;
  font-family: "Muli";
}

/* .accordion:hover {
    background-color: #ddd;
  } */
/* 
  .active {
    background-color: #ddd;
  } */

.panel {
  display: none;
  padding: 15px;
  background-color: #ffff;
  overflow: hidden;
}
.faq_content {
  font-size: 1rem;
  font-family: "Muli";
  font-weight: 600;
}

.show {
  display: block;
}

.add_register {
  gap: 1rem;
  padding: 1rem;
  width: 50%;
}
.add_register input {
  width: 100%;
  border-radius: 4px;
  text-indent: 1.2rem;
  border: 1px solid #ccc;
  font-size: 1rem;
  padding: 10px;
  overflow: hidden;
  margin: 0px;
}

.add_icon {
  display: flex;
  position: relative;
}
.add_icon svg {
  position: absolute;
  top: 50%;
  left: 15px;
  transform: translate(-50%, -50%);
  font-size: 1rem;
  color: #4285f4;
}

.add_icon select {
  width: 100%;
  font-size: 1rem;
  text-indent: 1.2rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
  box-sizing: border-box;
}

.errors {
  color: #ea4335;
  font-size: 14px;
  margin-top: 5px;
  margin: 0px;
}

.add_submit {
  width: 100%;
  background-color: #4285f4;
  color: #ffff;
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #4285f4;
  border-radius: 35px;
  cursor: pointer;
  margin: 0;
}

.add_submit:hover {
  background-color: #4285f4;
}

/* Reset Password styles */
.Password_container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.Add_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.add_back_btn {
  width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;
  background-color: #ffff;
  border-style: none;
  font-size: 1.5rem;
  border-radius: 5px;
  cursor: pointer;
}

#add_header_h2 {
  flex: 1;
  text-align: center;
  font-size: 1.1rem;
  font-weight: 400;
  margin: 0px;
  padding: 0.5rem;
}

.form_container {
  display: flex;
  flex-direction: column;
  margin: 0 1rem;
  width: 100%;
  align-items: center;
}

.view_psw {
  position: absolute;
  top: 15%;
  right: 30px;
  transform: translate(-50%, -50%);
  font-size: 1rem;
  color: #4285f4;
}
