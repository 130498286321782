.sidenav_container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.profile-user-name {
  font-size: 0.8rem;
  text-align: center;
}
.profile-user-businessname {
  font-size: 1rem;
  font-weight: 600;
  text-align: center;
  color: #20a0ff;
}
.profile-user {
  padding: 10px;
}

.sidenav-1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  min-height: 92vh;
  height: 100%;
  position: fixed;
  width: 220px;
  left: 0;
  z-index: 1;
  overflow: scroll;
  box-shadow: 0 4px 60px rgba(0, 0, 0, 0.08);
  padding-top: 0.5rem;
  transition: width 0.3s ease;
}

.sidenav-1 img {
  width: 100%;
  /* max-width: 3.5rem; */
  cursor: pointer;
}

.navlinks {
  display: grid;
  gap: 0.5rem;
  margin-top: 1rem;
}

.navlinks a {
  display: flex;
  flex-direction: row;
  gap: 0.2rem;
  padding: 0px 5px;
  align-items: center;
  text-decoration: none;
  color: #8e8e8e;
  margin-bottom: 10px;
  border-left: 4px solid #fff;
  transition: background-size 0.5s;
  background-image: linear-gradient(to right, white, white);
  background-size: 0% 100%;
  background-repeat: no-repeat;
}

.navlinks a:hover {
  color: #20a0ff;
  background-size: 100% 100%;
}

.nav-icon {
  font-size: 1.2rem;
  color: inherit;
  padding-left: 8px;
}
.icon_text {
  font-size: 16px;
  padding-left: 20px;
}

.navlinks a span {
  overflow: hidden;
}

.navActive {
  color: #20a0ff !important;
  border-left: 4px solid #20a0ff !important;
}

.navInactive {
  color: #707275;
}

.logout {
  border: none;
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  margin: 0 0.5rem;
  background-color: #fff;
  align-items: left;
  cursor: pointer;
  text-align: left;
  padding-left: 6px;
}
.log_text {
  color: #707275;
  margin: 0px;
  padding: 0px;
  padding-left: 15px;
  font-size: 0.9rem;
}

.user_container {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #e6e6e6;
  padding: 1rem 0;
}

.user_image_container {
  width: 50px;
  height: 50px;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border-radius: 50%;
  padding: 0 10px;
}

.user_image {
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  object-fit: cover;
  border-radius: 50%;
}

.logout_icon {
  padding: 0px;
  align-items: center;
  justify-content: center;
  color: #707275;
  padding-left: 5px;
}

.user_initial {
  width: 100%;
  height: 100%;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
  background-color: #ccc;
  color: #fff;
  border-radius: 50%;
}
.icon_wrapper {
  position: relative;
}
.icon_count {
  position: absolute;
  top: -5px;
  left: 19px;
  font-size: 0.8rem;
  color: white !important;
  width: 1rem;
  text-align: center;
  border-radius: 50%;
  height: 1rem;
  background-color: red;
}
@media screen and (max-width: 900px) {
  .sidenav-1 {
    overflow: scroll;
    width: 70px;
  }

  .navlinks a {
    background-color: transparent;
    transition: background-size 0.5s;
    background-image: linear-gradient(to right, white, white);
    background-size: 0% 100%;
    background-repeat: no-repeat;
  }

  .navlinks a:hover {
    color: #20a0ff;
    background-size: 100% 100%;
  }

  .sidenav-1 img {
    max-width: 3rem;
  }

  .log_text {
    display: none;
  }
  .icon_text {
    display: none;
  }

  .logout_icon {
    padding-left: 3px;
  }
}
