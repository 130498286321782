.TopNav {
  display: grid;
  grid-template-rows: 20% 55% 25%;
  width: 100%;
  height: 150px;
  background-color: #ffff;
  top: 0px;
  z-index: 20;
  padding: 0.5px;
}

.headercontainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: #edf4ff;
  padding: 0 5rem;
}

.userinter {
  display: flex;
  flex-direction: row;
  gap: 0.2rem;
  align-items: center;
  justify-content: center;
}
.hitext {
  font-size: 0.9rem;
  font-weight: 600;
  color: #2e2d2d;
  margin: 0px;
}

.nametext {
  font-size: 0.9rem;
  font-weight: 500;
  color: #4285f4;
  margin: 0px;
}
.smileicon {
  color: #4285f4;
  margin: 0px;
}

/* font-size: 0.9rem;
  font-family: 600;
  color: #2e2d2d;
  margin: 0px; */

.container {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
}

#line {
  text-align: center;
  font-size: 1.2rem;
  color: #7a7979;
  font-family: 600;
  margin: 0px;
}

.usermobilecontainer {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
  justify-content: left;
}
.mobiletext {
  display: flex;
  align-items: center;
  margin: 0px;
  color: #2e2d2d;
  font-size: 0.9rem;
  font-weight: 600;
}
.mnumer {
  font-size: 0.9rem;
  margin: 0px;
  color: #4285f4;
}
.timecontainer {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;
}
.timeicon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px;
  color: #fbbc05;
}
.time {
  font-size: 0.9rem;
  color: #2e2d2d;
  margin: 0px;
  font-weight: 600;
}

.maincontainer {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  background-color: #ffff;
  margin: 0 3rem;
}
.usercontainer {
  display: flex;
  flex-direction: column;
  gap: 0px;
  align-items: center;
  justify-content: center;
}
.userBname {
  font-size: 1.8rem;
  font-weight: 400;
  font-family: "Marcellus SC";
  align-items: center;
  text-align: center;
  width: 450px;
  overflow-wrap: break-word;
  padding: 0px;
  margin: 0px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  margin: 0px;
  color: #2e2d2d;
}
.userName {
  font-size: 1.3rem;
  font-weight: 400;
  font-family: "Marcellus SC";
  margin: 0px;
  color: #2e2d2d;
}

.bottomcontainer {
  display: grid;
  grid-template-rows: 1fr;
  background-color: #4285f4;
}

.logo_container {
  display: flex;
  margin-left: 20px;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 80px;
}

.logo_img {
  width: 100%;
  border-radius: 25%;
}

.navlinks {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: left;
  gap: 1.8rem;
  position: relative;
  margin: 0 4rem;
}

.navlinks a {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none;
  color: #2e2d2d;
  transition: background-size 0.5s;
  background-image: linear-gradient(to right, white, white);
  background-size: 0% 100%;
  background-repeat: no-repeat;
  /* border-bottom: 4px solid transparent; */
}

.icon_wrapper {
  position: relative;
}

.icon_count {
  position: absolute;
  top: -5px;
  left: 10px;
  font-size: 0.8rem;
  color: white !important;
  width: 1.2rem;
  text-align: center;
  border-radius: 50%;
  height: 1.1rem;
  background-color: #ea4335;
}

.nav-icon {
  font-size: 1.2rem;
  color: inherit;
}

.icon_text {
  font-size: 1rem;
  color: #ffff;
}

.navlinks a span {
  overflow: hidden;
}

.navlinks2 a span {
  overflow: hidden;
}

.items {
  display: flex;
  align-items: center;
  justify-content: center;
}
.navActive {
  color: #ffff !important;
  border-bottom: 3px solid #ffff !important;
}

.navInactive {
  color: #ffff;
}

.logo_container img {
  display: flex;
  width: 5rem;
}

.buttoncontainer {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
  justify-content: right;
}

/* logout  */
.logoutbtn {
  display: flex;
  align-items: center;
  justify-content: center;
}
.logout {
  border-style: none;
  background-color: #ffff;
  color: #2e2d2d;
  cursor: pointer;
  font-weight: 400;
  padding: 0px;
}
/* Notifications */
.notification-btn {
  position: relative;
}
.notification-btn > div {
  position: absolute;
  top: 30px;
  right: 5px;
  width: 350px;
  border-radius: 0.42rem;
  list-style: none;
  background-clip: padding-box;
  z-index: 10;
}

.notification-btn button {
  border: none;
  background-color: #ffff;
  cursor: pointer;
}
.count {
  position: absolute;
  top: 0px;
  right: 0;
  color: #ffff;
  background-color: #ea4335;
  width: 1.2rem;
  height: 1.2rem;
  border-radius: 5px;
  display: flex;
  font-size: 0.8rem;
  margin: 0px;
  align-items: center;
  justify-content: center;
}

/* toogle button User */
.user_active_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 90px;
  height: 32px;
  margin: 0px;
}

.switch input {
  display: none;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ea4335;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 24px;
  width: 24px;
  left: 4px;
  right: 4px;
  bottom: 4px;
  background-color: #ffff;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #34a853;
}

input:focus + .slider {
  box-shadow: 0 0 1px #4285f4;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(55px);
}

/*------ ADDED CSS ---------*/
.slider:after {
  content: "Ofline";
  color: #ffff;
  display: block;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  font-size: 10px;
  font-family: Verdana, sans-serif;
  right: 50px;
}

input:checked + .slider:after {
  content: "Online";
  position: relative;
  left: 60px;
}

@media screen and (max-width: 1280px) {
  .icon_text {
    font-size: 0.8rem;
  }
  .userBname {
    font-size: 1.3rem;
  }
  .userName {
    font-size: 1rem;
    font-weight: 400;
    font-family: "Marcellus SC";
    margin: 0px;
    color: #2e2d2d;
  }
}
