nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  position: sticky;
  top: 0;
  z-index: 1;
  font-size: 15px;
  font-family: "Muli";
  text-transform: bold;
  transition: background-color 0.5s ease;
  border-bottom: 1px solid #eee;
  box-shadow: 0 8px 17px rgb(0 0 0 / 5%);
}

.Logo {
  display: flex;
  align-items: center;
}

.Logo img {
  max-width: 400px;
  height: 80px;
  margin: 0 40px;
}

nav ul {
  display: flex;
  align-items: center;
}

nav ul .n_item {
  list-style-type: none;
}

nav ul .n_item {
  display: block;
  color: #333;
  padding: 0 0.8rem;
  margin: 0 20px;
  text-transform: bold;
  font-size: 15px;
  text-decoration: none;
}

nav ul .n_item:hover {
  color: #2eadff;
  /* border-bottom: 2px solid #2eadff; */
  transition: border-bottom 0.5s ease;
  cursor: pointer;
}

nav ul .n_item .nav_btn {
  z-index: 2;
  border-style: none;
  padding: 10px 20px;
  text-align: center;
  justify-content: center;
  font-size: 15px;
  text-transform: capitalize;
  font-weight: 400;
  border-radius: 5px;
  color: #ffff;
  background: #20a0ff;
  cursor: pointer;
  font-family: Muli;
  overflow: hidden;
}

nav ul .n_item.active {
  background-color: transparent;
  list-style: none;
  text-decoration: none;
  /* border-bottom: 4px solid #2eadff; */
}

.nav_btn:hover {
  background-color: transparent;
  color: #2eadff;
}

#Navlink-items {
  list-style: none;
  text-decoration: none;
}

.menu {
  display: none;
  border-style: none;
  background-color: transparent;
  font-size: 25px;
  margin-right: 0 10px;
  color: #000000;
  transition: background-color 0.3s ease;
  font-family: Muli;
  z-index: 2;
}

.no-underline {
  text-decoration: none;
  color: #333;
}

.no-underline:hover {
  color: #20a0ff;
}

@media (max-width: 1026px) {
  nav {
    display: flex;
    justify-content: space-between;
  }

  .Logo img {
    width: 100%;
    height: 80px;
    margin: 0 10px;
  }

  .menu {
    display: block;
    position: relative;
    align-items: center;
    justify-content: center;
    /* align-self: flex-end; */
  }

  nav ul {
    display: none;
    flex-direction: column;
    width: 100%;
    padding: 10px;
    margin: 0.2rem;
  }

  nav ul.open {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #3333;
    padding: 0;
    /* importnat */
    margin: 0;
  }

  .open {
    display: flex;
    flex-direction: column;
    position: absolute;
    background: #ffff;
    padding: 20px;
    margin: 0 auto;
    top: 55px;
    justify-content: center;
    align-items: center;
    height: auto;
    width: 100%;
  }

  nav.opened {
    background-color: orange;
  }

  #Navlink-items {
    border: 1px solid #e6e6e6;
    margin: 5px 5px 5px 5px;
    width: 97%;
    top: 50px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 5px;
    box-shadow: 0 8px 17px rgb(0 0 0 / 5%);
  }

  nav ul .n_item {
    list-style-type: none;
    padding: 10px;
  }
}

@media (max-width: 520px) {
  .Logo img {
    width: 100%;
    height: 60px;
  }

  .menu {
    margin-top: 5px;
    color: #000000;
    transition: background-color 0.3s ease;
  }

  .open {
    padding: 10px 0;
  }
}

/* ---Loader Styles--- */

/* ----Content_1 Styles---- */

.content-1-container {
  display: flex;
  flex-direction: row;
  font-size: 30px;
}

.Content-text {
  padding-left: 60px;
  padding-right: 10px;
  padding-top: 60px;
  padding-bottom: 20px;
  flex: 35%;
}

.Content-img {
  padding: 0px;
  flex: 59%;
}

.Home_list_checks {
  list-style-type: none;
}

#text-1 {
  font-size: 5.5vh;
  font-weight: 600;
  font-family: Muli;
  color: #333;
  margin-top: 0px;
  align-items: center;
  margin-bottom: 10px;
  line-height: 6vh;
  margin-top: 2px;
  text-shadow: 2px 2px 3px rgb(0 0 0 / 10%);
}

#text-2 {
  font-size: 5vh;
  font-weight: bold;
  font-family: Muli;
  color: #333;
  align-items: center;
  text-shadow: none;
  margin-top: 2px;
  margin-bottom: 10px;
}

#text-3 {
  color: #20a0ff;
  font-family: Muli;
  font-size: 5vh;
  margin-bottom: 10px;
  text-shadow: 2px 2px 3px rgb(0 0 0 / 10%);
  font-weight: bold;
  margin-top: 2px;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 2;
  }
}

#text-3 {
  animation: fadeIn 2.5s ease-in-out;
}

.checks {
  display: flex;
  flex-wrap: wrap;
  font-size: 1rem;
  font-family: Muli;
  font-size: 2vh;
  font-weight: 600;
  color: #000;
  margin-bottom: 20px;
  list-style-type: none;
  margin-top: 20px;
}

.check_list {
  padding-left: 5px;
  margin-bottom: 10px;
}

.Home_check_icon {
  color: gray;
  font-family: Muli;
  padding: 0px 2px 0px 4px;
  line-height: 4vh;
  font-size: 2vh;
  font-weight: 600;
}

.content_btns {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 1rem;
  padding-top: 2px;
}

.content_btn_image {
  width: 150px;
  height: 50px;
  align-items: left;
  justify-content: center;
  background-color: #fff;
}

.google-play-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  font-weight: bold;
  color: #646464;
  padding: 10px;
  border: 2px solid #646464;
  border-radius: 40px;
  cursor: pointer;
}

.google-play-icon {
  margin-right: 8px;
  font-size: 34px;
}

.google-play-icon2 {
  margin-right: 8px;
  font-size: 34px;
}

.google-play-text {
  text-align: left;
  justify-content: center;
  padding-left: 2px;
}

.google-play-text-top {
  padding-left: 2px;
  font-size: 2vh;
}

.google-play-text-down {
  text-align: left;
  justify-content: center;
  font-size: 3vh;
  line-height: 3vh;
}

.content_img {
  width: 100%;
  height: auto;
}

@media (max-width: 1076px) {
  .content_btns {
    flex-direction: row;
  }

  .google-play-icon2 {
    margin-right: 8px;
    font-size: 28px;
  }

  .google-play-icon {
    margin-right: 8px;
    font-size: 20px;
  }

  .google-play-text-down {
    text-align: left;
    justify-content: center;
    font-size: 2.2vh;
  }

  .google-play-text-top {
    padding-left: 2px;
    font-size: 2vh;
  }
}

@media (max-width: 320px) {
  .content_btns {
    gap: 0.2rem;
  }

  .google-play-icon2 {
    margin-right: 8px;
    font-size: 24px;
  }

  .google-play-icon {
    margin-right: 8px;
    font-size: 20px;
  }
}

@media (max-width: 1320px) {
  .content_2_image {
    width: 100%;
    max-width: 500px;
    height: auto;
  }
}

/* Responsive layout - makes a one column-layout instead of two-column layout */
@media (max-width: 1024px) {
  .content-1-container {
    flex-direction: column-reverse;
  }

  .Content-text {
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 0px;
    padding-bottom: 20px;
    flex: 50%;
    text-align: center;
  }

  .checks {
    font-size: 1rem;
    font-family: Muli;
    font-size: 2vh;
    padding-left: 0;
    align-items: center;
    justify-content: center;
  }

  .check_list {
    margin: 2px;
  }

  .Home_check_icon {
    padding: 0px 1px;
  }

  #text-1 {
    font-size: 3vh;
    font-weight: 600;
    font-family: Muli;
    margin: 0px;
    color: #333;
    align-items: center;
    text-shadow: 2px 2px 3px rgb(0 0 0 / 10%);
  }

  #text-2 {
    font-size: 4vh;
    font-weight: bold;
    font-family: Muli;
    color: #333;
    margin: 0px;
    align-items: center;
    line-height: 6vh;
    text-shadow: none;
  }

  #text-3 {
    color: #20a0ff;
    font-family: Muli;
    margin: 0px;
    font-size: 4vh;
    text-shadow: 2px 2px 3px rgb(0 0 0 / 10%);
    font-weight: bold;
    line-height: 6vh;
    padding-left: 15px;
  }

  .google-play-button {
    border-radius: 40px;
    padding: 5px;
    cursor: pointer;
    font-weight: bold;
    font-size: 16px;
  }

  .google-play-icon {
    margin-right: 8px;
  }

  .google-play-text {
    text-align: left;
    justify-content: center;
    padding-left: 2px;
  }

  .google-play-text-top {
    padding-left: 2px;
    font-size: 12px;
  }

  .google-play-text-down {
    text-align: left;
    justify-content: center;
    font-size: 16px;
    line-height: 2vh;
  }

  .content_btns {
    flex-direction: row;
    justify-content: center;
    padding: 0;
  }
}

@media screen and (max-width: 820px) {
  .content-1-container {
    flex-direction: column-reverse;
  }

  .Content-text {
    padding: 0;
    justify-content: center;
    align-items: center;
  }
}

/* ........Content_2 Styles........... */
.content_2_container {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  padding: 0 0rem;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: 30px;
  padding-left: 60px;
}

.content_2_extra_bold {
  color: #000;
}

@media screen and (max-width: 1024px) {
  .content_2_container {
    flex-direction: column;
    text-align: center;
    padding: 0;
  }

  .content_2_image {
    width: 100%;
    max-width: 350px;
    height: auto;
  }

  .content_2_text {
    text-align: center;
  }
}

@media screen and (max-width: 542px) {
  .Content-2_image {
    width: 100%;
    max-width: 350px;
    height: auto;
    padding: 0;
  }
}

.content_2_image {
  width: 100%;
  max-width: 700px;
  height: auto;
  border-radius: 20px;
  margin: 1rem 1rem;
  box-shadow: 0px 5px 15px rgba(33, 33, 33, 0.1);
  border: 5px solid #ccc;
}

.content_2_text {
  display: flex;
  flex-direction: column;
  /* width: 40%; */
}

#content_2_h1 {
  text-align: center;
  font-family: Muli;
  font-size: 5.5vh;
  margin: 0px 0px;
  text-shadow: 2px 2px 3px rgb(0 0 0 / 10%);
  color: #333;
  font-weight: bold;
  line-height: 6vh;
  /* padding-top: 20px;
  padding-bottom: 20px; */
}

#extra {
  font-size: 2.1rem;
  font-weight: 700;
  color: #20a0ff;
  padding: 0 5px;
  font-family: Muli;
  font-size: 5vh;
  margin: 0px 0px;
  text-shadow: 2px 2px 3px rgb(0 0 0 / 10%);
  font-weight: bold;
  line-height: 6vh;
}

.content_2_p {
  word-spacing: 0.8px;
  color: gray;
  text-align: justify;
  padding: 0;
  font-weight: normal;
  font-family: Muli;
  margin: 0;
  font-size: 2.2vh;
  line-height: 4vh;
  padding-right: 60px;
}

#p_to {
  word-spacing: 0.8px;
  color: #333;
  text-align: justify;
  padding: 0;
  font-weight: bold;
  font-family: Muli;
  margin: 0;
  font-size: 2.2vh;
  line-height: 5vh;
}

@media screen and (max-width: 1120px) {
  .content_2_container {
    flex-direction: column;
    margin-top: 20px;
  }

  .content_2_p {
    text-align: justify;
    padding: 0px;
  }
}

@media screen and (max-width: 840px) {
  .content_2_image {
    width: 100%;
    max-width: 500px;
  }
}

@media screen and (max-width: 520px) {
  .content_2_image {
    max-width: 300px;
    height: auto;
    margin: 0 0;
    padding: 0;
  }

  .content_2_p {
    padding: 0;
    margin: 0;
    font-size: 2.4vh;
    line-height: 4vh;
  }

  #p_to {
    padding: 0;

    margin: 0;
    font-size: 1.8vh;
    line-height: 3vh;
  }
}

@media screen and (max-width: 1360px) {
  .content_2_image {
    width: 100%;
    max-width: 600px;
    height: auto;
    border-radius: 20px;
    margin: 1rem 1rem;
    box-shadow: 0px 5px 15px rgba(33, 33, 33, 0.1);
    border: 5px solid #ccc;
  }
}

@media screen and (max-width: 1280px) {
  .content_2_image {
    width: 100%;
    max-width: 500px;
    height: auto;
    border-radius: 20px;
    margin: 1rem 1rem;
    box-shadow: 0px 5px 15px rgba(33, 33, 33, 0.1);
    border: 5px solid #ccc;
  }
}

@media screen and (max-width: 560px) {
  .content_2_image {
    max-width: 400px;
  }
}

@media screen and (max-width: 450px) {
  .content_2_image {
    max-width: 300px;
  }
}

@media screen and (max-width: 320px) {
  .content_2_image {
    max-width: 250px;
  }
}

@media screen and (max-width: 280px) {
  .content_btns {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0;
  }
}

/* .......Conetnt_3 styles......... */

.content_3_container_main {
  background-color: #f0f1f3;
  padding: 20px;
}

.content_3_card_main {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 2rem;
}

.content_3_card {
  flex-basis: 25%;
  box-sizing: border-box;
  text-align: center;
  font-family: Muli;
  border: 1px solid #ccc;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

@media screen and (max-width: 1410px) {
  .content_3_card {
    flex-basis: 30%;
  }
}

@media screen and (max-width: 1280px) {
  .content_3_card {
    flex-basis: 30%;
  }
}

.content_3_card_p_checks {
  color: #000;
}

#content_3_h1 {
  text-align: center;
  font-family: Muli;
  color: #333;
  font-size: 5.5vh;
  line-height: 5vh;
}

.feature_img {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  width: 100%;
  height: auto;
  max-height: 200px;
  border-radius: 8px;
}

.content_3_para {
  padding-left: 10px;
  padding-right: 10px;
}

/* Media Query for Tablet and Smaller Screens */
@media (max-width: 1024px) {
  .content_3_card_main {
    flex-direction: column;
  }

  .content_3_card {
    flex-basis: 100%;
    margin-bottom: 20px;
    width: 45%;
    align-items: center;
    justify-content: center;
    margin: auto;
  }
}

@media (max-width: 768px) {
  .content_3_card_main {
    flex-direction: column;
  }

  .content_3_card {
    flex-basis: 100%;
    margin-bottom: 20px;
    width: 50%;
    align-items: center;
    justify-content: center;
    margin: auto;
  }
}

@media (max-width: 522px) {
  .content_3_card_main {
    flex-direction: column;
  }

  .content_3_card {
    flex-basis: 100%;
    margin-bottom: 20px;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin: auto;
  }
}

/* Media Query for Mobile Screens */
@media (max-width: 768px) {
  .content_3_card {
    flex-basis: 100%;
    margin-bottom: 20px;
  }
}

/* ..........Content4 styles............ */

.text_container_1 {
  width: 100%;
  font-family: "Muli";
  padding-left: 20px;
}

.contnet_4_container {
  background-color: #f0f1f3;
  font-family: "Muli";
  padding: 20px;
}

#contnet_4_h1 {
  font-size: 5vh;
  margin-bottom: 20px;
  text-align: center;
  color: #333;
  font-weight: bold;
  line-height: 5vh;
}

.text_container {
  display: flex;
  flex-direction: row;
  padding: 0 10px;
}

.question_container {
  margin: 0 10px;
}

.accordion {
  background-color: #f0f1f3;
  color: #333;
  cursor: pointer;
  padding: 0px;
  width: 100%;
  border-style: none;
  text-align: left;
  outline: none;
  transition: background-color 0.3s;
  display: flex;
  align-items: center;
}

#content_4_p {
  font-size: 16px;
  font-weight: 600;
  font-family: "Muli";
  padding-left: 20px;
}

.Content_4_icon {
  font-size: 24px;
  font-weight: bold;
}

.panel {
  display: none;
  background-color: #f0f1f3;
  overflow: hidden;
  padding: 0 10px 15px;
  line-height: 1.6;
  color: #333;
  font-size: 14px;
}

#content_4_p2 {
  font-size: 14px;
  padding-left: 60px;
}

.show {
  display: block;
}

@media (max-width: 840px) {
  .accordion {
    font-size: 14px;
  }

  .text_container_1 {
    padding: 0px;
  }
}

@media (max-width: 520px) {
  .text_container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .question_container {
    margin: 0px;
    padding: 0px;
    border: none;
    margin-bottom: 15px;
  }

  .accordion {
    font-size: 16px;
  }

  .panel {
    padding: 0px;
  }

  .counterbox-container {
    border: none;
    align-items: center;
  }
}

/* ..........Content5 styles............ */

.content_5_container {
  display: flex;
  text-align: center;
  justify-content: center;
  font-family: Muli;
  gap: 10px;
  padding: 20px 10%;
}
.content_5_header {
  text-align: center;
  padding: 20px;
}
.content_5_img {
  width: 50%;
  padding: 20px;
}
.content_5_contact {
  width: 50%;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.content_5_image {
  max-width: 520px;
  height: auto;
  border-radius: 10px;
}

#content_5_h1 {
  text-align: center;
  font-size: 5vh;
  color: #333;
  padding: 0;
  margin: 0;
  line-height: 5vh;
}

#content_5_h5 {
  text-align: center;
  font-size: 1.3rem;
  padding: 5px;
  font-weight: 500;
  margin: 0;
}

.Content_5_success {
  display: flex;
  flex-direction: column;
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 110px;
  padding-bottom: 110px;
  border-radius: 10px;
}

.Content_5_success-icon {
  align-items: center;
  font-size: 60px;
  color: #3cb043;
  padding: 10px;
}

.Content_5_success_MSG {
  font-size: 2.5vh;
  font-family: Muli;
  padding: 10px;
  font-weight: bold;
}

.content_5_contact input {
  width: 100%;
  font-size: 1.2rem;
  margin: 10px 1rem;
  padding: 10px 0;
  border-radius: 5px;
  text-indent: 1rem;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border: 1px solid #ccc;
  font-family: Muli;
}

.error {
  margin: 0;
  color: #f98080;
  font-size: 1rem;
  font-weight: 400;
}

.content_5_contact label svg {
  font-size: 1.3rem;
  align-items: center;
  justify-content: center;
}

#message {
  height: 100px;
  resize: none;
}

.message {
  color: #20a0ff;
  margin-top: 10px;
}

.phone {
  color: #20a0ff;
  margin-top: 10px;
}

.office {
  color: #20a0ff;
  margin-top: 10px;
}

.person {
  color: #20a0ff;
  margin-top: 10px;
}

.text-field {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 400px;
}

.content_5_contact .content_5_bts {
  align-items: center;
  justify-self: center;
  display: flex;
  width: 90%;
  max-width: 380px;
  margin-left: 20px;
  /* margin-left: 22px; */
}

#content_5_btn {
  width: 100%;
  background-color: #20a0ff;
  font-size: 1.2rem;
  margin: 10px 1rem;
  padding: 10px 10px 10px 10px;
  border-radius: 5px;
  text-indent: 1rem;
  color: #fff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border: 1px solid #ccc;
  font-family: Muli;
}

@media screen and (max-width: 2560px) {
  .content_5_container {
    padding: 20px 10%;
  }
}
@media screen and (max-width: 1024px) {
  .content_5_container {
    padding: 0px 0;
  }
}
@media screen and (max-width: 880px) {
  .content_5_img {
    width: 100%;
    padding: 0px;
  }

  .content_5_container {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .content_5_img {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .content_5_image {
    width: 100%;
    height: auto;
    border: 4px solid #ffff;
    border-radius: 25px;
    box-shadow: 0px 5px 15px rgba(33, 33, 33, 0.1);
  }

  #content_5_h1 {
    align-items: center;
  }

  /* .content_5_bts #content_5_btn {
    max-width: 250px;
    cursor: pointer;
    font-family: Muli;
  } */
  .text-field {
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 1000px;
  }
}

@media screen and (max-width: 520px) {
  .content_5_container {
    padding: 0 10px;
    margin: 1rem 10px;
    text-align: center;
    justify-content: center;
  }
  .content_5_img {
    width: 100%;
    padding: 10px;
  }
  .content_5_contact {
    width: 100%;
  }

  .content_5_image {
    margin: 0px;
    width: 100%;
    height: auto;
  }

  #content_5_h1 {
    align-items: center;
    font-size: 1.8rem;
    padding: 10px 0;
    font-family: Muli;
  }

  #contnet_4_h1 {
    font-size: 1.8rem;
    margin-bottom: 20px;
    text-align: center;
    color: #333;
    font-weight: bold;
  }

  #content_3_h1 {
    text-align: center;
    font-family: Muli;
    color: #333;
    font-size: 1.8rem;
  }

  #content_2_h1 {
    font-size: 1.8rem;
    margin: 0px 0px;
  }

  #extra {
    font-size: 1.8rem;
    font-weight: 700;
    color: #20a0ff;
    padding: 0 5px;
    font-family: Muli;
    margin: 0px 0px;
    text-shadow: 2px 2px 3px rgb(0 0 0 / 10%);
    font-weight: bold;
  }

  #content_5_h5 {
    align-items: center;
    font-size: 1.1rem;
    margin: 0;
    font-family: Muli;
  }

  .number {
    font-size: 3vh;
  }
}

/* ..........Content6 styles............ */

.content_6_container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 0 3rem;
  background-color: #eff3f9;
}

.content_6_header {
  display: flex;
  flex-direction: column;
  text-align: left;
  padding: 5rem;
  margin: 0 3rem;
}

#content_6_h1 {
  justify-content: center;
  text-align: left;
  font-size: 2.2rem;
  font-weight: 600;
  color: #20a0ff;
  padding: 4rem 0 0 0;
}

#content_6_h5 {
  font-size: 1.3rem;
  font-weight: 600;
  color: #565f76;
}

#content_6_btn {
  font-size: 1rem;
  padding: 10px 25px;
  background-color: #20a0ff;
  color: #ffff;
  cursor: pointer;
  border-radius: 5px;
  border: 2px solid #20a0ff;
}

.content_6_img {
  display: flex;
  align-items: right;
  margin: 2rem 1rem 0 1rem;
}

.content_6_image {
  width: 100%;
}

@media screen and (max-width: 800px) {
  .content_6_container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 0 2rem;
    background-color: #eff3f9;
  }

  #content_6_h1 {
    font-size: 1.8rem;
  }

  #content_6_h5 {
    font-size: 1.1rem;
  }

  #content_6_btn {
    font-size: 0.8rem;
  }

  .content_6_img {
    display: flex;
    align-items: center;
  }
}

@media screen and (max-width: 520px) {
  .content_6_container {
    padding: 0 1rem;
  }

  #content_6_h1 {
    font-size: 1.6rem;
  }

  #content_6_h5 {
    font-size: 1rem;
  }

  #content_6_btn {
    font-size: 0.8rem;
  }

  .content_6_imgage {
    align-items: center;
    width: 100%;
  }
}

/*--------------Content-7--------------------*/

.content_7_container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.Content_7_header {
  text-align: center;
  padding: 20px;
  font-family: Muli;
}

.Content_7_item {
  flex-basis: 30%;
  padding: 20px;
  box-sizing: border-box;
  text-align: center;
  font-family: Muli;
}

.Content_7_item img {
  width: 100%;
  height: auto;
  max-width: 500px;
  border-radius: 8px;
}

.Content_5_header2 {
  font-size: 1rem;
  font-weight: 600;
  color: #565f76;
  font-family: Muli;
}

/* Media Query for Tablet and Smaller Screens */
@media (max-width: 1024px) {
  .content_7_container {
    flex-direction: column;
  }

  .Content_7_item {
    flex-basis: 48%;
    margin-bottom: 20px;
  }
}

/* Media Query for Mobile Screens */
@media (max-width: 768px) {
  .Content_7_item {
    flex-basis: 100%;
    margin-bottom: 20px;
  }
}

/* ........Footer styles........ */

.footer_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0rem;
  background-color: #2e2d2d;
  font-family: Muli;
  color: #fff;
}

.footer_1 {
  display: flex;
  flex-direction: row;
  gap: 40px;
  text-align: center;
  padding: 20px;
}

.footer_box1 {
  text-align: center;
  width: 50%;
  padding: 10px;
  margin: 20px;
  color: #fff;
}

.footer_box2_inside_container {
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 4rem;
}

.footer_box2 {
  width: 50%;
  color: #fff;
}

.footer_arrow_icon {
  color: #20a0ff;
}

.footer_image {
  width: 350px;
  height: auto;
}

.footer_para {
  text-align: justify;
}

.parent {
  list-style-type: none;
  padding: 0;
  text-align: center;
}

.child {
  display: flex;
  align-items: center;
  padding: 5px;
  color: #fff;
  font-size: 1rem;
  transition: all 0.5s ease;
  padding-bottom: 2px;
}

.child svg {
  margin-right: 5px;
}

.child svg:hover {
  font-weight: 700;
  padding-left: 10px;
}

.child:hover {
  cursor: pointer;
  padding-left: 20px;
}

#footer_link {
  color: #fff;
  border-bottom: 3px solid transparent;
}

#footer_link:hover {
  border-bottom: 3px solid #fff;
  width: fit-content;
}

.no-underline-footer {
  color: #fff;
  text-decoration: none;
}

.no-underline-footer:hover {
  cursor: pointer;
  /* padding-bottom: 0.2rem; */
  border-bottom: 3px solid #fff;
  width: fit-content;
}

.footer_contact {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.footer_2 {
  display: flex;
  text-align: center;
  border-top: 1px solid #d3d3d3;
  width: 100%;
  padding: 10px 0px;
}

.footer2_inside {
  width: 50%;
}

.footer_header {
  display: inline-block;
  border-bottom: 2px solid #fff;
  padding-bottom: 5px;
  text-align: center;
}

@media screen and (max-width: 1024px) {
  .footer_1 {
    flex-direction: column;
    text-align: center;
    gap: 0px;
  }

  .footer_box1,
  .footer_box2 {
    width: 100%;
    text-align: center;
    margin: 0;
  }

  .footer_2 {
    flex-direction: column-reverse;
  }

  .footer2_inside {
    width: 100%;
    padding: 0px;
    margin-top: 0px;
  }

  .footer_image {
    width: 100%;
    max-width: 300px;
  }

  .parent {
    text-align: center;
  }

  .child {
    font-size: 0.9rem;
  }

  .footer_para {
    text-align: justify;
    margin: 0px;
    padding-right: 20px;
  }

  .counterbox-container {
    border: none;
    align-items: center;
  }
}

#count-up-section {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 20px;
  background-color: #f0f1f3;
}

.counterbox-container {
  display: flex;
  align-items: center;
  width: 300px;
  margin: 20px;
  position: relative;
  justify-content: center;
  border-right: 3px dashed #ccc;
}

.counterbox-container-last {
  display: flex;
  align-items: center;
  width: 300px;
  margin: 20px;
  position: relative;
  justify-content: center;
}

.Counterbox-img img {
  width: 100%;
  height: auto;
}

.Counter-icon {
  color: #20a0ff;
  padding-left: 5px;
  font-size: 20px;
}

.counterbox-number {
  flex: 1;
  text-align: center;
  font-family: "Muli";
}

.number {
  font-size: 4.5vh;
  font-weight: bold;
  text-align: left;
  padding-left: 10px;
  color: #2e2d2d;
}

.headline {
  font-size: 18px;
  margin-bottom: 0px;
  text-align: left;
  color: #20a0ff;
  margin: 0;
}

@media screen and (max-width: 820px) {
  .number {
    font-size: 3vh;
    font-weight: bold;
    text-align: left;
    padding-left: 10px;
    color: #2e2d2d;
  }
}

@media screen and (max-width: 912px) {
  .number {
    font-size: 3vh;
    font-weight: bold;
    text-align: left;
    padding-left: 10px;
    color: #2e2d2d;
  }
}

@media screen and (max-width: 550px) {
  .counterbox-container {
    margin: 0px;
    border-right: none;
  }

  .number {
    font-size: 3vh;
    font-weight: bold;
    text-align: left;
    padding-left: 20px;
    color: #2e2d2d;
  }

  .counterbox-number {
    padding-left: 20px;
  }

  .Counterbox-img img {
    padding-left: 10px;
    padding-right: 10px;
    height: auto;
    width: 80%;
  }

  #count-up-section {
    padding: 10px;
  }

  .counterbox-container-last {
    width: 250px;
    margin: 0px;
  }
}

@media screen and (max-width: 1410px) {
  .counterbox-container {
    border-right: none;
    /* padding: 2rem; */
  }

  .counterbox-container-last {
    width: 250px;
    margin: 0px;
  }

  .counterbox-number {
    padding-left: 20px;
  }

  #count-up-section {
    padding: 20px;
  }
}
